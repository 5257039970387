.head {
    //padding: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.warning {
    padding: 20px;
    background-color: #ff9239;
    display: flex;
    flex-direction: row;
}

.icon {
    color: white;
    margin-right: 20px;
}

.body {
    flex: 1;
}

.breadRow {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

.breadLeft {
    margin-right: 10px;
    min-width: 60px;
    min-height: 30px;
    align-items: center;
    display: flex;
}

.breadRight {
    flex: 1;
    align-items: center;
    display: flex;
}

.buttonMargin {
    margin: 1em 0;
}