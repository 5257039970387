.pageWrapper {
    .categoryHeader {
        margin: 1em;

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }
    }
}

.categoriesWrapper {
    margin: 0.5em;

    .gray {
        color: darkgray;
    }

    .subHeader {
        font-size: 16px;
        font-weight: bold;
        margin: 1.5em 0 0.5em 0;
    }

    .word {
        &:before {
            margin: 0 0.5em;
            content: '-';
        }
        margin-left: 0.5em;
    }

    .companyGroup {
        margin: 0.5em 0;
    }

    .companyCorrect {
        color: green;
        .companyDescription {
            color: darkgreen;
        }
    }

    .companyWrong {
        &.companyCorrectness4 {
            color: #ffae00;
            .companyDescription {
                color: #8b5e00;
            }
        }
        &.companyCorrectness4 {
            color: #ff8600;
            .companyDescription {
                color: #8b4700;
            }
        }
        &.companyCorrectness4 {
            color: #ff6400;
            .companyDescription {
                color: #8b2a00;
            }
        }
        &.companyCorrectness0 {
            color: red;
            .companyDescription {
                color: darkred;
            }
        }
    }

    .companyName,
    .companyDescription,
    .companyWords {
        display: block;
        &:before {
            color: black;
            display: inline-block;
            width: 10em;
        }
    }

    .companyName {
        &:before {
            content: 'Namn: ';
        }
        font-weight: bold;
    }

    .companyDescription {
        &:before {
            content: 'Verksamhet: ';
        }
        font-style: italic;
        color: darkgray;
    }

    .companyWords {
        &:before {
            content: 'Ord: ';
        }
    }

    .noResults {
        color: darkgrey;
    }
}
