:global {
    .CodeMirror {
        border: 1px solid #eee;
        height: auto;
        width: 100%;
    }

    .CodeMirror-lint-tooltip {
        z-index: 10000;
    }
}
