.quickLinksSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1em 0;

  &>div {
    background-color: #f8f8f8;
    display: block;
    width: 100%;
    padding: 1em 0.5em;
    &:nth-of-type(2n) {
      background-color: #ffffff;
    }
  }

  &:before {
    content: 'Shortcuts';
    background-color: #ffffff;
    border-bottom: 2px solid #dddddd;
    display: block;
    font-weight: bold;
    padding: 0.5em;
    width: 100%;
  }
}