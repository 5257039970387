.dataContainer {
    background: #fff;
    padding: 15px;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    border-radius: 4px;
    margin-bottom: 10px;
}

.head {
    font-weight: bold;
}

.block {
    padding: 15px 0;
    border-top: 1px solid #ccc;

    &:last-of-type {
        padding: 15px 0 0 0;
    }
}

.dataInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .dataInput {
        display: flex;
        flex: 1;
    }

    > label,
    > span,
    > div {
        min-width: 110px;
        margin-right: 5px;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.classification {
    margin-left: 5px;
}
