.wrapper {
  display: block;
  padding: 1em;
  width: 100%;

  .header {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
  }

  .info {
    font-style: italic;
  }

  .list {
    margin: 1em 0;
    display: block;
    width: 100%;

    .row {
      display: flex;
      width: 100%;

      .input {
        display: flex;
        flex: 1;

        input[type="text"] {
          flex: 1;
        }
      }

      .inputTitle {
        font-weight: bold;
        text-decoration: underline;
      }

      >:not(.buttons) {
        flex: 1;
      }
    }
  }

  input:not([data-editing="true"]) {
    background-color: lightgrey;
    color: white;
  }

  input.disabled {
    cursor: not-allowed;
  }

  .test {
    margin: 2em 0 1em 0;

    .testResult {
      padding: 1em;
      border: 1px dashed darkgrey;
      margin: 0.5em;
      background-color: lightyellow;

      .testName {
        font-weight: bold;
        text-decoration: underline;
      }

      .testLink {
        margin: 1em 0;

        .label {
          font-weight: bold;
        }
      }

      .testImage {
        margin: 0 0 1em 0;
        display: block;
        width: 640px;
        height: 400px;
      }
    }
  }

  .billing {
    margin: 1em 0;
    padding-bottom: 1em;
    border-bottom: 1px solid lightgrey;

    .keyValue {
      display: flex;
      align-items: center;
      margin: 2px 0;

      .key {
        margin-right: 1em;
      }
      .value {
        font-weight: bold;
      }
      .input {
        flex: 1;
      }
    }
  }
}

