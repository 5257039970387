@import '../../../../../../../../styles/base';

.translationButtons {
    display: flex;
    flex-direction: column;
}

.translateButton {
    flex: 1;
}

.textError {
    border-top: 1px solid red;
    color: red;
    font-weight: bold;
    margin-top: 4px;
    padding-top: 2px;
}
