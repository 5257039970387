.title {
    font-size: 40px;
    text-align: center;
}
.input {
    border: 1px solid #ccc;
    border-radius: 3.01px;
    width: 400px;
    height: 45px;
    display: block;
    padding: 5px;
    margin-bottom: 10px;
}
.form {
    margin: auto;
    width: 400px;
    form {
        margin-bottom: 10px;
    }
}
.important.postButton {
    padding: 10px;
    border-radius: 3px;
    margin-right: 10px;
}
