.wrapper {
    margin: 0 1em;

    .note {
        &:before {
            padding-right: 0.5em;
        }

        display: inline-block;
        padding: 0.5em 1em;
        background-color: #ffe184;
        font-weight: bold;
        border: 2px solid #d9b400;
    }

    .celebrityRow {
        display: flex;
        padding: 0.3em 0;
        align-items: center;

        &.hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &.notPickable {
            background-color: lightgrey;
            color: darkgray;
        }

        &.example {
            font-weight: bold;
        }

        > * {
            margin: 0 1em;
            cursor: default;
        }

        > *:first-child {
            margin-left: 0;
        }

        > *:last-child {
            margin-left: 0;
        }

        .watched {
            width: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .name {
            flex: 2;
        }

        .personnr {
        }

        .personnote {
            flex: 5;
        }

        .copy {
            width: 3em;
            padding: 0;
        }

        .remove {
            width: 2em;
            padding: 0;
        }

        input.formError {
            background-color: #ffa093;
        }

        input.formSuccess {
            background-color: #d4ffc0;
        }

        .connection {
            flex: 2;
        }

        .emails {
            flex: 3;
        }

        .newspapers {
            flex: 3;
        }

        .nothing {
            content: '- ingen -';
            color: darkgray;
        }
    }

    .descriptionInline {
        font-style: italic;
        color: #666666;
    }
}

.editable {
    .celebrityRow {
        &:not(.example) {
            .name,
            .personnr,
            .personnote,
            .emails,
            .newspapers {
                cursor: text;
            }

            &.notPickable {
                .name,
                .personnr,
                .personnote {
                    cursor: not-allowed;
                }
            }
            &.pickable {
                .name,
                .personnr,
                .personnote {
                    cursor: pointer;
                }
            }
        }
    }
}
