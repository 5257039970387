.container {
    position: fixed;
    width: 100vw;
    z-index: 1;
    background: #e7ebf1;
    box-shadow: 0 8px 6px -6px #999;
}

.sideMenuOpen {
    padding: 20px 215px 0 20px;
}

.sideMenuClosed {
    padding: 20px 35px 0 20px;
}

.headerButtons {
    display: flex;
    a {
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
    div > a {
        &:last-child {
            margin-right: 10px;
        }
    }
}

.showFilters {
    padding: 10px;
    display: block;
}

.hideFilters {
    padding: 10px;
    display: none;
}

.filterTop {
    display: flex;
}

.filterTopContainer {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 10px;
    select {
        margin-left: 10px;
    }
}

.searchContainer {
    flex: 1 1 auto;
    position: relative;
}

.searchInput {
    width: 100%;
    padding: 5px;
}

.searchClear {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
}

.filterBottom {
    display: flex;
    align-items: center;
    height: 45px;
    margin-top: 5px;
}

.filterBottomLeft {
    flex: 1 1 auto;
    font-size: 13px;
    display: flex;
    padding-right: 10px;
}

.filterBottomRight {
    flex: 1 1 auto;
    font-size: 13px;
    display: flex;
    padding-right: 10px;
}

.filterBottomButtons {
    display: flex;
    flex-wrap: nowrap;

    :global .fa {
        position: relative;

        &-ontop-opposite {
            &:after {
                font-size: 0.6em;
                bottom: -2px;
                color: red;
                opacity: 0.8;
                right: -2px;
            }
        }
    }
}

.filterBottomHideShowButtons {
    display: flex;
    flex-wrap: nowrap;
}
