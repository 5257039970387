@import '../../../../../../../../styles/base';

.subscription:nth-child(even) {
    background-color: #ccc;
}

.toomanycompanies {
    background-color: #f0000050;
}

.allLocations {
    background-color: yellow;
}

.hrDashed {
    border: 0;
    border-bottom: 1px dashed #ccc;
    //background: #999;
}

.hrDouble {
    overflow: visible;
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
}