.headerBig {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 6px 0;
}

.headerSmall {
    font-size: 15px;
    font-weight: bold;
    margin: 8px 0 6px 0;

    &.italic {
        font-style: italic;
    }
}

.headerSmaller {
    font-size: 13px;
    font-weight: bold;
    margin: 8px 0 6px 0;
}
.smallfont {
    font-size: xx-small;
}

.subArea {
    margin-left: 1em;
    border-left: 5px solid #737dff;
    padding-left: 1em;

    &:not(:first-of-type) {
        margin-top: 1em;
    }
}

.subExplanation {
    font-size: 13px;
    margin-left: 1em;
    border: 1px dashed #ffe5cd;
    background: #f8ffea;
    padding: 0.5em;
    max-width: 500px;
}

.subExplanation div:not(:first-of-type) {
    font-size: 12px;
}

.subExplanationFrequency:before {
    content: 'Frequency: ';
    font-weight: normal;
}

.subExplanationRecommendation:before {
    content: 'Recommendation: ';
    font-weight: normal;
}

.subExplanationFrequency,
.subExplanationRecommendation,
.subExplanationWarn {
    margin: 0.5em;
    font-weight: bold;
}

.subExplanationRecommendationText {
    margin-left: 0.5em;
}

.scopeWrapper {
    font-size: 15px;
    margin: 1em 0;
}

.scopeWrapperOr {
    margin: 0.5em;
    font-size: 13px;
}

.scopeRow {
    display: flex;
}

.scopeOne {
    display: flex;

    div:not(:first-of-type):before {
        content: 'och';
        margin: 0 1em;
        font-size: 13px;
        font-weight: normal;
        border: none;
    }
}

.scopeItem {
    font-weight: bold;
}

.scopeTrash {
    margin-left: 1em;
    color: darkred;
    cursor: pointer;
}

.scopeAddRow {
    color: #737dff;
    text-decoration: underline;
    cursor: pointer;
}

.scopeAdd {
    margin-right: 1em;
}

.scopeDropdown {
    margin-top: 0.5em;
    display: block;
}

.horizontal {
    display: flex;

    label:not(:first-of-type) {
        margin-left: 2em;
    }
}

.beforeInput {
    font-size: 16px;
}

.listItem {
    display: flex;
    margin-top: 0.5em;
    font-size: 15px;
    align-items: center;

    .listItemLabel {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .listItemInput {
        text-align: right;
    }

    .listItemValue {
        width: 50px;
        margin-right: 0.5em;
    }

    .remove,
    .add {
        cursor: pointer;
        user-select: none;
    }
    .remove {
        color: #ff5d61;
    }

    .add {
        color: #7ad07a;
    }
}

.large {
    margin-top: 10px;
    font-size: 16px;
}

.resolutionXByXSeparator {
    margin: 0 1em;
}

.controlLeftAligned {
    padding-left: 0;
    padding-top: 0;
    margin: 0 0 6px 0;
}

.tooltip {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
    color: #8888ff;

    &.warn {
        color: #ff0000;
    }
}

.tooltipsmaller {
    font-size: 14px;
    color: #7b7b7b;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgba(80, 80, 80, 1);
    color: #fff;
    text-align: center;
    padding: 0.7em 1em;
    border-radius: 4px;
    font-weight: normal;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 1.5em;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.margin {
    margin: 1em 0;
}

.warnSection {
    padding: 0.5em 1em;
    margin-top: 2em;
    background-color: rgba(255, 0, 0, 0.2);

    hr {
        background: rgba(0, 0, 0, 0.2);
    }

    input {
        min-width: 30em;
    }

    .confirmButton {
        margin-top: 1em;
    }
}

.oneLineHigh {
    height: 1.5em;
    line-height: 1.5em;
}

.blacklistLine {
    display: flex;
    width: 100%;

    &:not(:first-of-type) {
        margin-top: 0.2em;
    }

    &:nth-of-type(2n-1) {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .blacklistLabel {
        flex: 1;
        align-items: center;
        display: flex;
    }
}

.inlineSelect {
    margin-left: 1em;
}

.controlAndInput {
    display: flex;

    .checkboxInlineText {
        margin: 0.5em 0 0.5em 1em;
    }
}

.notActive {
    opacity: 0.6;
}

.inline {
    display: flex;
    align-items: center;
}

.bold {
    font-weight: bold;
}

.summaryGroup {
    margin: 0.5em 0 1em 0;
    .summarySection {
        margin-left: 0.5em;
    }
}

.summaryGroupTitle {
    font-size: 16px;
    font-weight: bold;
}

.propmixSummaryEntry,
.propmixSummaryEntryRow2,
.zipGroupRow {
    &:first-of-type {
        margin-top: 0.5em;
    }
    display: flex;
    align-items: center;
    > * {
        flex: 1;
    }
    .noFlex {
        flex: none;
        &Item {
            padding-left: 7px;
        }
    }

    .marginright {
        margin-right: 0.5em;
    }
    .scheduleDate {
        margin-right: 0.5em;
        display: flex;
        align-items: center;
        select {
            height: 100%;
        }
    }
}

.red {
    background-color: red;
    color: white;
}

.propmixSummaryEntryRow2 {
    margin-bottom: 0.5em;
}

.zipGroupRow {
    margin-bottom: 0.5em;
}

.salealertWrapper {
    display: flex;
    align-items: center;
    &:nth-of-type(2n-1) {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.salefilterColumn, .salealertColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.salefilterColumn {
    &:nth-of-type(2n-1) {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.salealertColumn {
    flex: 1;
}

.salefilterRow, .salealertRow {
    display: flex;
    align-items: center;
    padding-top: 2px;

    > *:not(div) {
        flex: 1;
    }
    div {
        margin: 0 0.5em;
    }

    .alertEmail {
        width: 20em;
    }

    .orLabel {
        color: darkgrey;
        font-size: 0.9em;
        font-weight: bold;
    }

    .moreFlex {
        flex: 4;
    }
    .noFlex {
        flex: none;
        .item {
            padding-left: 7px;
        }
    }

    .inlineFlex {
        margin: 0 0.5em;
        display: inline-flex;
        align-items: center;
        > * {
            margin: 0 0.5em;
        }
    }
    .priceAndOr {
        font-weight: bold;
        border: 2px dashed blue;
        padding: 4px;
    }
    .fakeDisabled {
        background-color: lightgrey;
    }
    .fakeDisabledSelect {
        > div:first-of-type {
            background-color: lightgrey;
        }
    }
    .titleWidth {
        width: 100px;
    }
}

.toggle {
    cursor: pointer;
    user-select: none;
}

.settings {
    padding-top: 1em;
    display: flex;
    align-items: center;
}

.triggerOptions {
    width: 100%;
    display: flex;
    align-items: center;

    > *:nth-child(2) {
        padding-left: 1em;
        flex: 1;
    }
}

.grid {
    display: grid;
    grid-template-columns: 30% 23% 24% 23%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 0.5em;
    grid-row-gap: 0.5em;

    .header {
        font-size: 1.1em;
        font-weight: bold;
        text-decoration: underline;
        padding-bottom: 2em;
    }

    .disabled {
        color: darkgrey;
    }

    .specialForAllHeader {
        font-style: italic;
    }

    .spacingRow {
        height: 2em;
    }

    .slideContainer {
        width: 100%;
        display: flex;
        align-items: center;

        & div {
            text-align: center;
            width: 3em;
            margin: 0 1em;
        }

        .slider {
            flex: 1;
            appearance: none;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            transition: opacity 0.15s ease-in-out;
            height: 10px;
        }

        .slider:hover {
            opacity: 1;
        }

        .slider::-webkit-slider-thumb {
            appearance: none;
        }

        .slider::-moz-range-thumb {
            width: 10px;
            height: 15px;
            background: #04aa6d;
            cursor: pointer;
        }

        .slider::-webkit-slider-thumb {
            width: 10px;
            height: 15px;
            background: #04aa6d;
            cursor: pointer;
        }

        &[disabled] {
            opacity: 0.3;
            .slider::-moz-range-thumb {
                cursor: default;
            }
            .slider::-webkit-slider-thumb {
                cursor: default;
            }
        }
    }

    &.gridCondo {
        grid-template-columns: 25% 18% 19% 19% 18%;
        grid-template-rows: auto auto auto auto auto;
    }
}

.flippingGrid {
    grid-template-columns: 5% 27% 27% 27% 5% 5% 5%;
    grid-template-rows: auto auto auto auto auto auto auto;
}

.priceIncreaseGrid {
    grid-template-columns: 50% 25% 20% 5%;
    grid-template-rows: auto auto auto auto;
}

.priceIncreaseGrid,
.flippingGrid {
    display: grid;
    grid-gap: 0.5em;
    grid-row-gap: 0.5em;
    align-items: center;

    .header {
        font-size: 1.1em;
        font-weight: bold;
        text-decoration: underline;
        padding-bottom: 2em;
    }
}

.buttons {
    margin: 1em 0;
}

.pointer {
    cursor: pointer;
}

.na {
    color: darkgrey;
}

.ukSummaryEntry {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    & > span {
        font-size: 85%;
        font-weight: bold;
        margin: 0 1em;
    }
}

.propmixSummaryEntryGroup:not([data-isOdd="true"]) {
    background-color: #d4d4d4;
}

.rangeStatusError, .rangeStatusOk {
    flex: 0;
}

.rangeStatusError {
    color: red;
}

.rangeStatusOk {
    color: green;
}

.vr {
    display: block;
    margin: 0 6px;
    width: 1px;
    height: 30px;
    background-color: #CCC;
}

.warning {
    background-color: red;
}

.expandArea {
    cursor: pointer;
}

.note {
    background-color: lightyellow;
    border: 1px dashed #ffffc2;
    width: 500px;
    padding: 0.5em;
    margin: 0.5em;
}

.scheduleButton {
    margin: 1em 0;
    padding: 2em;
}

.soldColor {
    background-color: #86e1ff;
}

.forsaleColor {
    background-color: #20ff14;
}

.summaryColor {
    background-color: #ffb514;
}

.saleAlertsHeader {
    display: flex;
    align-items: center;

    i {
        margin-left: 2em;
        padding: 0.5em;

        &.copyPaste {
            cursor: pointer;
        }
    }
}