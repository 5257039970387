@import '../../styles/base';

.button {
    background-color: $primary-color;
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    min-width: 32px;
    text-align: center;
    user-select: none;

    a {
        color: #fff;
        text-decoration: none;
    }

    &:hover {
        background-color: lighten($primary-color, 5);
        text-decoration: none;
    }
}

.button {
    border: 1px solid #fff0;
    i {
        font-size: 16px;
        position: relative;
        top: -1px;
    }

    span {
        font-size: 12px;
    }

    > *:nth-child(2) {
        &:empty {
            margin: 0;
        }

        margin-left: 5px;
    }
}

.selected {
    @extend .button;
    background-color: #08c472;
    &:hover {
        background-color: darken(#08c472, 10);
    }
}

.discreet {
    @extend .button;
    background-color: lighten($primary-color, 15);
    &:hover {
        background-color: lighten($primary-color, 20);
    }
}

.iconOnly {
    @extend .button;
    background-color: inherit;
    color: #4ba3f3;
    border: 0;
    margin: 0;
}

.onRow {
    margin-left: 5px;
}

.disabled {
    @extend .button;
    cursor: default;
    background-color: #8e8e8e;
    color: #323232;
    &:hover {
        background-color: #8e8e8e;
    }
}

.grayDisabled.disabled {
    color: #808080;
}

.notSelected {
    @extend .button;
    background-color: #d6d6d6;
    color: #aaaaaa;

    &:hover {
        background-color: #ccc;
    }
}
.muted {
    @extend .button;
    background-color: #eee;
    border: 1px solid #bbb;
    color: #333;

    &:hover {
        background-color: #ccc;
    }
}

.remove {
    @extend .button;
    background-color: #cc3d4c;
    &:hover {
        background-color: darken(#cc3d4c, 10);
    }
}
.tight {
    padding: 5px;
    //&:hover{
    //    background-color: darken(#cc3d4c, 10);
    //}
}

.warn {
    @extend .button;
    background-color: $warn-color;
    &:hover {
        background-color: $warn-color-darker;
    }
}

.flagSelected {
    box-shadow: 0px 0px 2px 2px lightgreen, 0 0 2px 2px black;
}

.cancel {
    @extend .button;
    background-color: #9fa9bd;
    &:hover {
        background-color: darken(#9fa9bd, 10);
    }
}

.deleteArgument {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -13px;
    height: unset;
    background-color: #f0f8ff;
    color: red;
    height: 14px !important;
    width: 14px !important;
    left: -13px;
    border-radius: 50%;
    &:hover {
        text-decoration: none;
    }
}
.addArgument {
    position: unset;
    height: unset;
    width: 1em;
    padding: unset;
    background-color: unset;
    color: green;
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: none;
    }
}

.inputNormal {
    width: unset;
}
