@import '../../../styles/base';

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.content {
    flex: 1;
    overflow: auto;
    display: flex;
}

.sidebar {
    width: $sideBarWidth;
    background-color: #2e333e;
    z-index: 1500;
    color: white;

    h1 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 0px;
        a {
            color: white;
        }
    }

    hr {
        border: none;
        height: 1px;
        box-shadow: 0 1px 0 $sidebarColor-lighter, inset 0 1px 0 $sidebarColor-darker;
    }
}

.sidebarCollapsed {
    @extend .sidebar;
    width: 40px;

    a {
        display: flex;
        justify-content: center;
        padding: 6px 0;
        border-left: 0;

        &[class*='active'] {
            box-shadow: inset 4px 0 $primary-color;
        }

        i {
            margin: 0;

            &[class*='fa-newspaper-o'] {
                margin: 0 !important;
            }
        }

        span {
            visibility: hidden;
            width: 0;
        }
    }
}
