@import '../../../../../../../styles/base';
$thisWidth: 230px;

.sidebar {
    position: fixed;
    top: $headerHeight;
    bottom: 0;
    margin-left: -$bigPaddingLeft;
    width: $thisWidth;
    padding: 20px $bigPaddingLeft;
    background-color: #fff;
    //border-right: 1px solid #000000;
    // box-shadow: 2px 0 4px rgba(0,0,0,.40);
    overflow: auto;
    z-index: 1000;
    color: #333;
    h1 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 0;
        a {
            color: #666;
        }
    }
}

.main {
    margin-left: $thisWidth;
}

.itemContainer {
    @extend .card;
    background: transparent;
}

.generatedText {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.articleFooter {
    padding: 0 10px 10px 10px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.link {
    display: block;
}
.h1 {
    font-weight: bold;
    font-size: 16px;
    margin-top: 6px;
}
.h2 {
    font-weight: bold;
    margin-top: 6px;
}

.mainNoMenu {
    padding: 20px;
}

.cardWrapper {
    // display: flex;

    .itemContainer:not(:last-child) {
        flex: 1;
    }

    .itemContainer:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .itemContainerTrash {
        display: flex;
        align-items: center;
        background-color: #ff706b;
        color: white;
        font-weight: bold;
    }
}

.paginationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1em;

    .paginationNumber {
        font-weight: bold;
        margin: 0 6px;
    }
}

.filterContainer {
    margin: 0 0 10px 0;
}

.inline {
    display: inline-flex;
}

.inlineHorizCenter {
    display: inline-flex;
    align-items: center;
    width: 100%;

    span {
        margin: 0 0.5em;
    }

    .container {
        flex: 1;
        max-width: 300px;
    }
}

.linkStyle {
    margin: 0 0 0.5em 0;
    display: inline-block;
    cursor: pointer;
    color: darkblue;
    text-decoration: underline;
}

.lagfartPrintList {
    width: 300px;
    display: inline-block;
}

.optionalArea {
    margin: 8px;
    padding: 8px;
    background-color: #dcb36c;
    border: 1px solid white;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;

    .tag {
        border: 1px solid lightyellow;
        background: lightyellow;
        border-radius: 8px;
        padding: 4px 6px;
        margin: 0.25em 0.75em;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;

        &.selected {
            border: 1px solid lightgreen;
            background: lightgreen;
        }

        &.unselected {
            border: 1px solid #ee9fa8;
            background: #ee9fa8;
        }

        &.specialTag {
            background: #ffec6c;
            border: 1px solid #ffec6c;
        }
    }
}

.smallLabel {
    font-size: 10px;
}

.rowItems {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.smallLink {
    font-size: 12px;
    color: #3fa8ff;
    margin: 0 1em;
    cursor: pointer;
    user-select: none;

    &:hover {
        color: blue;
    }

    &.noMargin {
        margin: 0;
    }
}

.smallSpan {
    font-size: 12px;
    margin: 0 1em;
}

.onlySelectedCustomerSubscriptionLink {
    font-size: 12px;
    margin-left: 1em;
    cursor: pointer;
    color: #3fa8ff;
    user-select: none;

    &:hover {
        color: blue;
    }
}

.chooseLocaleParent {
    margin-bottom: 0.5em;

    .chooseLocale {
        display: inline-block;
        padding: 5px;
        height: 40px;
        cursor: pointer;

        :not(:first-child) {
            margin-left: 0.5em;
        }

        &.selected {
            border: 2px solid gray;
            background: rgba(0, 255, 0, 0.4);
        }
    }
}

.electricityInput {
    width: 20em;
}

.ukArea {
    display: flex;
    align-items: center;
    padding: 0.5em 0;

    span {
        margin-right: 1em;
    }

    .areaSelect {
        flex: 1;
    }
}