.uploadBtnWrapper {
    margin: 5px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        border: none;
        color: gray;
        background-color: #f5f7fa;
        border-radius: 8px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }

    input[type='file'] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    img {
        width: 40px;
    }

    button {
        border: none;
        color: gray;
        background-color: #f5f7fa;
        border-radius: 8px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 50px;
        height: 50px;
        position: relative;
        font-family: FontAwesome;

        &:after {
            content: '';
            display: none;
        }
        // &:hover {
        //     background: rgba(250, 200, 200, 0.5);
        // }

        &:hover {
            background: rgba(0, 0, 0, 0.8);
            img {
                opacity: 0.3;
            }
            &:after {
                content: '\f040';
                font-family: FontAwesome;
                font-size: 22px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }
}

.listName {
    font-size: 16px;
    padding: 0 0 2px 0;
    border: 1px solid #ccc;
    border-width: 0 0 1px 0;
}

.listNameContainer {
    margin-bottom: 5px;
    &:last-child {
        margin: 0;
    }
}

.listOfImages {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
}

.error {
    background: #de5754;
    padding: 5px;
    color: #ffffff;
    margin: 5px 0;
}
