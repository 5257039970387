:global {
    // Lets disable scrolling for body when modal open
    .ReactModal__Body--open {
        overflow: hidden;
    }

    .modal {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3000;
        pointer-events: none;
        transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transform: scale(0.75);
        outline: none;

        &--open {
            transform: scale(1);
        }

        &--before-close {
            transform: scale(0.5);
        }

        &--none {
            width: auto;
            .modal__content {
                height: auto;
            }
        }

        &--small {
            width: 30vw;
            .modal__content {
                height: calc(50vh - 150px);
            }
        }

        &--medium {
            width: 50vw;
            .modal__content {
                height: calc(70vh - 150px);
            }
        }

        &--large {
            width: 80vw;
            .modal__content {
                height: calc(100vh - 150px);
            }
        }

        &__main-content {
            background: #fff;
            pointer-events: auto;
            transition: height 0.3s linear;
            background: #ffffff;
        }

        &__header {
            padding: 10px;
            background: #e7ebf1;
            font-size: 20px;
            height: 54px;
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        &__content {
            padding: 20px 30px;
            background: #ffffff;
            overflow-y: auto;
            max-height: calc(100vh - 150px);
        }

        &__footer {
            padding: 10px;
            display: flex;
            background: #e7ebf1;
            align-items: center;
            height: 54px;

            &-left {
                flex: 1 1 auto;
            }
            &-right {
                display: flex;
            }
        }
    }

    .cover {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
        z-index: 3010;
        transition: opacity ease-in-out;
        opacity: 0;
        outline: none;

        &--open {
            transition-duration: 0.3s;
            opacity: 1;
        }

        &--before-close {
            transition-duration: 0.15s;
            opacity: 0;
        }
    }
}
