.mainNoMenu {
    padding: 20px;
}

.filterContainer {
    margin: 0 0 10px 0;
}

.inline {
    display: inline-flex;
}

.onlySelectedCustomerSubscriptionLink {
    font-size: 12px;
    margin-left: 1em;
    cursor: pointer;
    color: #3fa8ff;
    user-select: none;

    &:hover {
        color: blue;
    }
}

.smallLabel {
    font-size: 10px;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;

    .tag {
        border: 1px solid lightyellow;
        background: lightyellow;
        border-radius: 8px;
        padding: 4px 6px;
        margin: 0.25em 0.75em;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;

        &.selected {
            border: 1px solid lightgreen;
            background: lightgreen;
        }

        &.unselected {
            border: 1px solid #ee9fa8;
            background: #ee9fa8;
        }

        &.specialTag {
            background: #ffec6c;
            border: 1px solid #ffec6c;
        }
    }
}

.searchResultStr {
    color: #f3a22b;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.pageNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 2em;
}

.saleTypesInput {
    width: 300px;
}

.saleType {
    margin: 0 0.5em;
    cursor: pointer;
    border: 1px solid #ffffe0;
    background: #ffffe0;
    border-radius: 8px;
    padding: 4px 6px;
    font-size: 10px;
    font-weight: bold;
}

.saleTypesContainer {
    align-items: center;

    .saleTypesInput {
        width: 500px;
    }
}

.oneRow {
    display: flex;
    margin: 0.5em 0;
    align-items: center;
    > * {
        margin: 0 0.5em;
    }
}

.buttons {
    display: flex;
    margin: 0.5em;

    &>* {
        margin-right: 1em;
    }
}

.settings {
    display: flex;
    flex-direction: column;
    margin: 1em;
    padding: 1em;
    border: 1px dashed darkgrey;
    background-color: lightyellow;

    .setting > label {
        display: flex;
        padding: 0 0.3em;
        align-items: center;

        & > b {
            width: 200px;
        }
    }

    .setting > a {
        margin-right: 1em;
    }
}