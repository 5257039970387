.container {
    display: block;
    margin: 0.5em 0;

    .title {
        font-weight: bold;
        font-size: 115%;
    }

    .note {
        border: 1px dashed rgba(0, 0, 0, 0.3);
        background-color: lightyellow;
        padding: 0.5em;
        margin: 0.5em 0;
        max-width: 500px;
    }

    .copyPasteCollage {
        user-select: none;
        cursor: pointer;
        margin: 0 1em;
        &::before {
            margin-right: 0.3em;
        }
    }

    .subContainer {
        margin: 8px 0 6px 0;
    }

    $collageWidth: 60;
    $collageHeight: 36;

    .collageLayoutBox {
        margin-right: 1em;
        display: inline-block;
        position: relative;
        width: $collageWidth + 0px;
        height: $collageHeight + 0px;
        background-color: white;

        .innerBox {
            font-family: monospace;
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: lightgrey;
            position: absolute;
            border: 1px solid black;
        }

        .half {
            width: 50%;
            height: 50%;
        }

        .thirdhalf {
            width: 33%;
            height: 50%;
        }

        .sixtypctwidth {
            width: 60%;
            height: 100%;
        }

        .fortypctwidthhalfheight {
            width: 40%;
            height: 50%;
        }

        .thirdwitdh {
            width: 33%;
            height: 100%;
            &:first-of-type {
                border-right: none;
            }
            &:nth-of-type(2) {
                border-right: none;
                border-left: none;
            }
            &:last-of-type {
                border-left: none;
            }
        }

        .horizfade {
            background: rgb(211, 211, 211);
            background: linear-gradient(
                90deg,
                rgba(211, 211, 211, 1) 0%,
                rgba(161, 161, 161, 1) 50%,
                rgba(211, 211, 211, 1) 100%
            );
        }

        .tl {
            top: 0;
            left: 0;
        }

        .tr {
            top: 0;
            right: 0;
        }

        .bl {
            bottom: 0;
            left: 0;
        }

        .br {
            bottom: 0;
            right: 0;
        }

        .c {
            &.half {
                left: 25%;
                top: 25%;
            }

            &.thirdwitdh {
                left: 33%;
                top: 0;
            }
        }

        .tc {
            &.thirdhalf {
                top: 0;
                left: 33%;
            }
        }

        .bc {
            &.thirdhalf {
                bottom: 0;
                left: 33%;
            }
        }
    }
}
