@import '../../styles/base';

.pageWrapper {
  width: 100%;
  .categoryHeader {
    margin: 1em;
    padding-bottom: 4em;

    h3 {
      font-style: italic;
    }

    .description {
      font-weight: bold;
      margin-bottom: 1em;
    }
  }
}

.reload {
  padding: 5px 0;
  cursor: pointer;
}

.approvedArticleBlock {
  .oneArticle {
    @extend .card;

    border: 1px solid darkgray;
    margin-top: 2em;

    .infoRow {
      display: flex;
      align-items: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin: 0;
      font-size: 14px;
      background: #7c8494;
      padding: 10px;
      color: #fff;

      .infoCol {
        text-align: center;
        flex: 1;
        border-left: 1px solid darkgray;

        &:first-of-type {
          border-left: 0;
        }
      }
    }

    .taskLink {
      padding: 0.5em 0 0 10px;

      .comment {
        font-style: italic;
        font-weight: bold;
        color: darkgrey;
      }
    }

    .textBlock {
      margin: 10px;
      padding: 15px;
      border: 1px solid #d2d9e4;
      background: #e7ebf1;

      .title {
        font-weight: bold;
        font-size: 18px;
      }

      .text {
        font-size: 14px;

        .paragraph {
          padding: 10px 0;
          border-top: 1px dashed #aaa;
        }
      }
    }

    .approveChanges {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1em 0;
    }

    .testLoadingWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 4em 0;
    }

    .thumbsup {
      color: darkgreen;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 4em 0;
    }

    .actionsRow {
      display: flex;
      border-top: 1px solid darkgray;

      .actionsCol, .actionsColAlt {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        flex: 1;
        border-left: 1px solid darkgray;
        user-select: none;
      }

      .actionsCol {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 255, 0, 0.1);
        }

        &:first-of-type {
          border-left: 0;
        }

        .testTowardsRevision {
          font-size: 10px;
          color: darkgray;
        }
      }

      .actionsColAlt {

      }

      .actionsColBtn {
        padding: 1.5em;
        background-color: white;
        color: black;
      }

      .actionsColBtnDisabled {
        color: black;
        background-color: lightgrey;
        color: darkgrey;

        &:hover {
          background-color: lightgrey;
        }
      }

      .actionRemove {
        background-color: rgba(255, 0, 0, 0.1);

        &:hover {
          background-color: rgba(255, 0, 0, 0.2);
        }
      }
    }
  }
}

.okMessage {
  position: fixed;
  display: block;
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  z-index: 1000;

  > div {
    display: flex;
    justify-content: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    line-height: 100px;
  }

  .okContent {
    color: darkgreen;
    font-weight: bold;
  }
}

.inline {
  display: inline-flex;
}

.genericStats {
  .header {
    font-weight: bold;
    text-decoration: underline;
  }

  .line {
    display: inline-block;
    margin: 1em;
    padding: 1em;
    border: 1px solid darkgrey;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 200px;
    cursor: pointer;

    .db {
      font-weight: bold;
      margin-bottom: 3px;
    }

    .keyVal {
      display: flex;

      .key {
        width: 100px;
      }

      .value {
        &.ok {
          color: green;
          font-weight: bold;
        }

        &.failed {
          color: red;
          font-weight: bold;
        }
      }
    }
  }
}