@import '../../../../../../../styles/base';

.agrumentsContainer {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    margin-right: 5px;
}

.contextContainer {
    display: flex;
    align-items: stretch;
    margin-bottom: 4px;
}

.firstRow {
    display: flex;
}

.thirdRow {
    display: flex;
    margin-top: 5;
    align-items: center;
}

.result {
    max-height: 200;
    width: 100%;
}

.arrayLength {
    margin-left: 2;
    font-size: 12;
}

.leftContextContainer {
    display: flex;
    // align-items: center;
    margin-bottom: 4px;
    background: aliceblue;
    padding: 5px;
    width: 100%;
    flex-direction: column;
    border: brown 1px dotted;
}

.rowContextContainerSpace {
    flex: 1 1 auto;
}

.rightContextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-conent: center;
    padding: 0px 0px 4px 0;
}

.inputSuggestion {
    flex: 1 1 100%;
}

.outputValueContainer {
    margin-left: 5px;
    flex: 1 1 100%;
    display: flex;
    align-items: center;

    pre {
        user-select: all;
        max-height: 200px;
    }
}

.outputValue {
    width: 100%;
    margin: 0 2px;

    resize: vertical;
    // overflow: scroll;
    // height: 200px;
}

.propertyContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex: 1 1 auto;

    &:only-child {
        margin-bottom: 0;
    }

    > div {
        &:first-of-type {
            min-width: 80px;
            margin-right: 5px;
        }
    }

    & input {
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #ccc;
        width: 100%;
    }
}

.variableWarning {
    margin: 2px 0 0 5px;
    color: red;
}

.functionDescription {
    margin: 5px 0;
    padding: 0 5px;
}

.deleteButtonContainer {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
}

.marginTop5 {
    margin-top: 5px;
}

.functionSelect {
    margin-right: 5px;
}
