@import '../../../styles/base';

.main {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;

    i {
        text-align: center;
        width: 20px;
        margin-right: 10px;
    }

    &.h1 {
        font-size: 20px;
    }

    &:hover {
        background-color: $sidebarColor-lighter;
        text-decoration: none;
    }
}

a.main {
    &:hover {
        background-color: $sidebarColor-lighter;
        text-decoration: none;
    }
}

.active {
    //@extend .main;
    background-color: $sidebarColor-darker;
    box-shadow: inset 4px 0 $primary-color;

    &:hover {
        background-color: $sidebarColor-lighter;
    }
}
