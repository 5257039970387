.bold {
    font-weight: bold;
}

.revisionsHead {
    background: #fff;
    min-height: 55px;
    max-height: 300px;
    padding: 0 20px;
    z-index: 500;
    display: flex;
    box-shadow: 0 2px 0 #d7dce4;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}

.floatingContainer {
    margin: 0 20px;
    font-weight: bold;
    position: fixed;
    bottom: 5px;
    box-shadow: 0px 0px 17px 10px rgba(0, 0, 0, 0.24);
}

.floatingMessage {
    background: #fff;
    padding: 5px;
    border: 1px solid #ccc;
}

.checkbox {
    display: inline-block;
}

.documentRevisionName {
    font-size: 16px;
    margin: 5px 0;
}

.textComment {
    font-size: 14px;
}

.textEditButton {
    margin-top: 10px;
    padding: 4px 4px 2px;
    min-width: 0px;
}

.textEditButtonIcon {
    font-size: 12px !important;
}

.closeButton {
    margin-left: 10px;
}
