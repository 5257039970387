@import '../../styles/base';

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
    z-index: 9999;
}

.percent {
    height: 2px;
    background: $primary-color;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    transition: all 200ms ease;
}
