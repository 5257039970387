.settingsContainer {
    margin: 1em;
}

.chunkRow, .chunkRowHistory {
    display: flex;
    align-items: center;
    width: 100%;

    > * {
        margin: 0 1em;
    }

    .municipalitiesSelect {
        flex: 1;
    }
}

.chunkRowHistory {
    margin-bottom: 2em;
}

.addChunkButtonWrapper {
    margin-top: 1em;
}
