$exampleColBg: #d3d3d3;
$column1Bg: #b3ebb6;
$column2Bg: #bdccff;
$column3Bg: $exampleColBg;

.container {
    position: absolute;
    max-height: 200px;
    z-index: 1;
    overflow-y: auto;
    width: inherit;
    box-shadow: 3px 3px 9px;

    tr {
        td:nth-child(1) {
            background: $column1Bg;
            vertical-align: top;
        }

        td:nth-child(2) {
            background: $column3Bg;
        }

        td:nth-child(2):not(.exampleCol) {
            background: $column2Bg;
        }

        td:nth-child(3) {
            background: $column3Bg;
        }

        &:nth-child(even) {
            td:nth-child(1) {
                background: lighten($column1Bg, 10);
            }

            td:nth-child(2) {
                background: lighten($column3Bg, 10);
            }

            td:nth-child(2):not(.exampleCol) {
                background: lighten($column2Bg, 10);
            }

            td:nth-child(3) {
                background: lighten($column3Bg, 10);
            }
        }
    }

    & td {
        padding: 0 0.35em;
        min-width: 135px;
    }
    & .selected {
        background-color: hsla(0, 0%, 100%, 0.5);
        outline: 1px solid #904e4e;
    }
    & .separator {
        border-bottom: 1px solid black;
    }
}
.propertyContainer {
    width: 100%;

    & input {
        width: 100%;
    }
}

.exampleCol {
    font-style: italic;
    font-size: 0.85em;
    font-family: monospace;
    text-align: center;
}
