@import '../../../../styles/_base.scss';

.formField {
    margin: 0 0 25px 0;

    &__label {
        display: block;
        margin: 0 0 5px 0;
        font-weight: bold;

        &--inline {
            margin: 0 10px 0 0;
        }
        &--reverse {
            margin-left: 10px;
        }
    }

    &__input {
        input,
        select,
        textarea {
            padding: 5px;
            border: 1px solid darken($inputColor, 8);
            display: block;
            color: inherit;
            border-radius: 0;
        }
        select {
            height: 30px;
            background: #ffffff;
            border: 1px solid darken($inputColor, 8);
            display: block;
            color: inherit;
            border-radius: 0;
        }

        .MuiAutocomplete-input {
            border: 0;
        }

        &--inline {
            input,
            select,
            textarea {
                padding: 0;
            }
        }
        &--reverse {
        }
    }

    &__description {
        color: #828282;
        margin: 2px;
    }

    &--inline {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
