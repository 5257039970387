@import '../../../../../../../styles/base';
.container {
    background: #fff;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    border-radius: 4px;
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    > * {
        margin-right: 5px;
        margin-bottom: 5px;
    }
}
