:global {
    @import 'base';
    @import '~normalize.css/normalize';
    @import 'month-picker.scss';
    @import './react-popup.scss';
    @import 'pagination.scss';
    @import 'custom-flags';

    // Some best-practice CSS that's useful for most apps
    // Just remove them if they're not what you want
    html {
        box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
        font-size: 14px;
        background: #e7ebf1;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    pre {
        border: 1px solid #999;
        background-color: #f0f0f0;
        padding: 8px;
        border-radius: 4px;
        color: #191e23;
        overflow: auto;
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .flexFullCol {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        // overflow-y: scroll;
    }

    .centerTop {
        align-items: center;
        margin-top: 100px;
    }

    hr {
        border: none;
        height: 1px;
        background: #ccc;
    }

    a {
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
    }

    a:hover {
        text-decoration: underline;
    }

    .standardContainer {
        padding: $bigPaddingLeft;
        width: 100%;
    }

    .fixedContainer {
        display: flex;
        flex-direction: column;
    }

    .fixedHeader {
        background: #fff;
        box-shadow: 0 2px 0 #d7dce4;
        padding: $bigPaddingLeft;
        z-index: 100;
    }

    .underFixedHeader {
        padding: $bigPaddingLeft;
        width: 100%;
        overflow: auto;
    }

    //input {
    //    box-sizing: border-box;
    //    border: 1px solid #ccc;
    //    font: 12px Consolas, Menlo, "Liberation Mono", Courier, monospace;
    //    height: 25px;
    //    line-height: 25px;
    //    padding: 2px 4px;
    //}
    //button {
    //    box-sizing: border-box;
    //    background: #f5f5f5;
    //    border: 1px solid #ccc;
    //    //border-radius: 3.01px;
    //    color: #333;
    //    cursor: pointer;
    //    display: inline-block;
    //    font-family: inherit;
    //    font-size: 14px;
    //    font-variant: normal;
    //    font-weight: 400;
    //    height: 25px;
    //    line-height: 25px;
    //    margin: 0;
    //    padding: 4px 10px;
    //    text-decoration: none;
    //    vertical-align: baseline;
    //    white-space: nowrap;
    //}

    .table {
        width: 100%;

        & th {
            background: #fff;
            font-weight: bold;
            text-align: left;

            a {
                color: #000;
            }
        }

        & > thead > tr > th,
        & > thead > tr > td {
            border-bottom: 2px solid #ddd;
            padding: 8px;
        }

        & > tbody > tr > th,
        & > tbody > tr > td {
            border-top: 1px solid #ddd;
            padding: 8px;
        }

        & > tbody > tr {
            background: #fff;
            &.errorRow {
                background: darken(#f5dfdf, 3);
            }
            &:nth-of-type(odd) {
                background: #f8f8f8;
                &.errorRow {
                    background: #f5dfdf;
                }
            }

            &:hover {
                background: darken(#f8f8f8, 12);
                &.errorRow {
                    background: darken(#f5dfdf, 10);
                }
            }
        }
    }

    .h2 {
        display: block;
        font-size: 18px;
        font-weight: bold;
    }

    .h3 {
        display: block;
        font-size: 16px;
        font-weight: bold;
    }

    .onRow {
        margin-right: 10px;
    }

    .onRow:last-child {
        margin-right: 0;
    }

    .inline-margin > * {
        margin-right: 10px;
    }

    .inline-margin > .month-picker {
        display: inline-block;
    }

    .inline-margin > *:last-child {
        margin-right: 0;
    }

    .inline-separator {
        color: #a9a9a9;
    }

    .tab-contents {
        padding-top: 1em;
    }

    //.sidebarSmall{
    //    font-size: px
    //}

    .article-block {
        margin: 0.5em 0;
        overflow-wrap: break-word;
    }

    .box {
        border: 1px solid lightgray;
    }

    // .article-tag {
    //     font-weight: bold;
    //     margin: 0.1em 0.3em;
    //     border-radius: 0.5em;
    //     padding: 0.2em 0.5em;
    //     display: inline-block;
    // }

    .section:not(:first-of-type) {
        padding-top: 1em;
    }

    .shadow-green {
        box-shadow: 0 2px 2px 0 rgba(0, 160, 0, 0.14), 0 3px 1px -2px rgba(0, 160, 0, 0.2),
            0 1px 5px 0 rgba(0, 160, 0, 0.12);

        @extend .card;
    }

    .shadow-red {
        box-shadow: 0 2px 2px 0 rgba(160, 0, 0, 0.14), 0 3px 1px -2px rgba(160, 0, 0, 0.2),
            0 1px 5px 0 rgba(160, 0, 0, 0.12);

        @extend .card;
    }

    .flex-row {
        display: flex;

        .flex-col {
            flex: 1;
        }

        .flex-vertical-center {
            display: flex;
            align-items: center;
        }
    }

    .tabs,
    .tabsSub {
        background-color: #fff;

        &.is-selected {
            background-color: #f8f8f8 !important;
        }

        &:hover {
            background-color: #eee !important;
        }

        &:focus,
        &:active {
            box-shadow: none !important;
        }

        &-lang {
            padding: 5px !important;
        }
    }

    .tabsBar,
    .tabsBarSub {
        background-color: $primary-color !important;

        &-lang {
            height: 2em !important;
        }
    }

    .tabsSub {
        margin-top: 10px;
        background-color: #eee;

        &.is-selected {
            background-color: #e8e8e8 !important;
        }

        &:hover {
            background-color: #ddd !important;
        }
    }

    .tabsBarSub {
        background-color: $secondary-color !important;
    }

    input,
    select,
    textarea {
        padding: 5px;
        border: 1px solid darken($inputColor, 8);
        display: block;
        color: inherit;
        border-radius: 0;

        &[type='color'] {
            padding: 0;
            border: none;
        }
    }

    select {
        height: 30px;
        background: #ffffff;
        border: 1px solid darken($inputColor, 8);
        display: block;
        color: inherit;
        border-radius: 0;
    }

    .tooltip {
        position: relative;
        display: inline-block;
        margin-left: 0.5em;

        .fa {
            font-size: 14px;
            font-weight: bold;
            color: #8888ff;
        }

        &.warn {
            color: #ff0000;
        }
    }

    .tooltipsmaller {
        font-size: 14px;
        color: #7b7b7b;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: calc(60vw - 300px);
        background-color: rgba(80, 80, 80, 1);
        color: #fff;
        text-align: center;
        padding: 0.4em;
        border-radius: 4px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        top: -5px;
        left: 18px;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    [type='file'].file-input {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;

        & + label {
            background-color: $primary-color;
            display: inline-flex;
            align-items: center;
            padding: 8px 12px;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            min-width: 32px;
            text-align: center;
            user-select: none;
        }

        &:focus + label,
        + label:hover {
            background-color: lighten($primary-color, 5);
            text-decoration: none;
        }

        &:focus + label {
            background-color: lighten($primary-color, 5);
            text-decoration: none;
        }
    }

    .localHeader {
        div {
            background-color: rgb(255, 0, 171) !important;
            border-color: #9c007f !important;
        }
    }

    .devHeader {
        div {
            background-color: rgb(255, 128, 0) !important;
            border-color: darken(rgb(255, 128, 0), 10) !important;
        }
    }
    .fa {
        &-ontop-opposite {
            &:after {
                content: '\f05e';
                font-size: 0.5em;
                position: absolute;
                bottom: 0px;
                right: 0;
                opacity: 0.5;
                color: red;
            }
        }
    }
}
