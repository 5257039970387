.link {
    color: #0a84ac;
    text-decoration: underline;
    cursor: pointer;
}

.editorWrapper {
    padding: 0.5em;
    margin: 0.5em;
    background-color: #94c5d9;

    .itemRow {
        &:nth-last-of-type(2n) {
            background-color: #8bc0d7;
        }
        display: flex;

        .itemName,
        .itemDesc {
            margin: 0.5em;
        }

        .itemDesc {
            &:before {
                content: ' - ';
            }
            flex: 1;
            font-style: italic;
            color: #505050;
        }
    }
}
