.container {
    display: block;
    margin: 0.5em 0;
}

.title {
    font-weight: bold;
    font-size: 115%;
}

.note {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    background-color: lightyellow;
    padding: 0.5em;
    margin: 0.5em 0;
    max-width: 500px;
}

.button {
    margin: 0 0.5em 0 0;
}

.toplistTagEntry {
    margin: 0.5em 0 0.5em 6px;
    padding-left: 6px;
    display: flex;
    > *:not(.button) {
        flex: 1;
        margin-right: 0.5em;
    }
}

.oneSetting {
    display: block;
}
