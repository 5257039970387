.wrapper, wrapperLagfart {
    border-left: 5px solid darkred;
    margin: 1em 0 1em 1em;
    padding-left: 1em;
}

.wrapperLagfart {
    margin: 1em 0 1em 2em;
}

.setting {
    display: flex;
}

.text {
    font-size: 16px;
    font-weight: bold;
}

.textInput {
    width: 500px;
}
