.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 2em;
  border: 1px solid gray;
  background-color: white;
}

.pairRowEditing {
  display: flex;
  align-items: center;

  .pairKey, .pairValue {
    flex: 1;
  }

  .check {
    width: 10px;
    margin-right: 5px;
    display: inline-block;
    color: green;
    cursor: pointer;
  }

  .remove {
    width: 10px;
    margin-right: 0.5em;
    display: inline-block;
    color: red;
    cursor: pointer;
  }
}

.pairRow {
  position: relative;
  display: block;

  &:hover {
    background-color: darken(white, 10%);
  }

  .pairAdd {
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    color: darkgrey;
  }

  .pairKey {
    display: inline-block;
  }

  .pairEq {
    display: inline-block;
  }

  .pairValue {
    display: inline-block;
  }
}


