.wrapper {
    margin: 1em;

    .section {
        margin: 0.3em 0;
    }

    .label {
        margin-right: 0.5em;
    }

    .separator {
        margin: 0 1em;
    }

    .inline {
        display: inline-flex;
    }

    h1 {
        font-size: 16px;
        font-weight: bold;
    }

    .results {
        margin-top: 1em;

        .oneResult {
            .resultTitle {
                font-size: 120%;
                font-weight: bold;
            }

            .resultTags {
                display: flex;

                .resultTag {
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 3px;
                    background-color: lightgrey;
                    padding: 2px 6px;
                    margin: 2px 8px 2px 0;
                    text-wrap: none;
                }
            }

            .resultImages {
                display: block;
                width: 100%;
                height: 350px;
                white-space: nowrap;
                overflow: auto hidden;

                .resultImageWrapper {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 0.5em;
                    text-align: center;

                    .resultImage {
                        height: 300px;

                        img {
                            width: 300px;
                            max-height: 300px;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }

            .imageCustomSize {
                width: 600px;
                max-height: 600px;
            }
        }
    }

    .tagRow {
        display: flex;

        .tagKey {
            font-weight: bold;
            width: 150px;
        }

        .tagVal {
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 3px;
            background-color: lightgrey;
            padding: 2px 6px;
            margin: 2px 8px 2px 0;
            text-wrap: none;
            cursor: pointer;
            user-select: none;

            &.selected {
                background-color: lightgreen;
            }
        }
    }
}
