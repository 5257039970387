.page {
    margin: 1em;
}

.buttonBar {
    position: fixed;
    width: 100%;
    margin: -1em;
    padding-left: 1em;
    margin-bottom: 1em;
    background: #999999;
    z-index: 1000;
    display: flex;
    align-items: center;
    > * {
        margin: 1em;
    }

    .convertingPanel {
        margin: 0 4em;
        color: white;
        font-weight: bold;

        .icon {
            margin: 0 1em;
        }

        .red {
            color: #ff4444;
        }
        .green {
            color: #27ef27;
        }

        .blue {
            color: #1fedff;
        }

        .yellow {
            color: #6d5454;
        }
    }

    .flags {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        > * {
            margin: 0 0.5em;
        }

        .oneFlag {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .countryVariant {
            margin-top: 0;
            &:first-of-type {
                margin-top: 6px;
            }
            font-size: 10px;
            font-weight: bold;

            &.selectedVariant {
                color: white;
            }
        }

        .chooseCountry {
            font-size: 2em;
            font-weight: bolder;
            color: white;
            opacity: 1;
            position: relative;
            left: 0;
            margin-right: 50px;

            animation: fadeOut 2s forwards, position 2s infinite alternate-reverse ease-in-out;
            animation-delay: 8s, 0s;
        }

        @keyframes fadeOut {
            from {opacity: 1;}
            to {opacity: 0;}
        }

        @keyframes position {
            from {left: 0px;}
            to {left: 50px;}
        }

        .selected {
            outline: 5px solid #5b5959;
        }

        :not(.selected) {
            cursor: pointer;
        }
    }
}

.offsetButtonBar {
    padding-top: 5em;
}

.headerBig {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 6px 0;
    text-decoration: underline;
}

.subArea {
    margin-left: 1em;
    border-left: 5px solid #737dff;
    padding-left: 1em;
}

.mapWrapper {
    display: flex;
    flex: 1;
    height: 800px;
    margin-bottom: 6em;

    .map {
        width: 1100px;
        height: 800px;

        &:empty {
            background: lightblue;
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
                content: 'Loading map...';
            }
        }
    }

    .rightPanel {
        height: 800px;
        overflow-y: scroll;
        padding-left: 1em;
        flex: 1;

        .copyButton {
            color: #c78a20;
            margin: 0.5em;
            font-size: 0.8em;
            font-weight: bold;
            cursor: pointer;
        }

        .linkedToSection {
            font-size: 0.85em;
            color: darkgrey;
        }

        .countryPartPicker {
            .title {
                font-weight: bold;
                text-decoration: underline;
                padding: 0.5em 0;
            }

            .countryParts > div {
                cursor: pointer;
                padding: 0.1em 0;
            }
        }

        .currentGeosTitle {
            font-size: 1.2em;
            text-decoration: underline;
        }

        .combinedGroupAndTrash {
            display: flex;
            padding-right: 10px;

            .combinedGroup {

                .expandAndName {
                    display: flex;

                    .expandable {
                        margin-right: 0.5em;

                        &.disabled {
                            color: grey;
                        }
                    }
                }

                flex: 1;
                margin: 0.3em;
                margin-right: 0;
                padding: 0.3em;
                border: yellow;
                background: lightyellow;
                &.selected {
                    background: #ffc469;
                }

                .combinedName {
                    font-weight: bold;
                }

                .combinedPart {
                    display: flex;
                    flex-direction: column;

                    .selected {
                        font-weight: bold;
                    }

                    .poiRow {
                        font-size: 0.8em;
                        display: flex;
                        align-items: center;
                        font-weight: normal;
                        margin-bottom: 0.2em;
                        &:nth-of-type(2n+1) {
                            margin-bottom: 1em;
                        }

                        .dot {
                            cursor: pointer;
                            margin: 0 0.2em;
                        }

                        .selected {
                            color: white;
                            text-shadow: 1px 1px 7px black;
                        }

                        .tiny {
                            font-size: 0.6em;
                        }
                        .small {
                            font-size: 0.8em;
                        }
                        .normal {
                            font-size: 1em;
                        }
                        .large {
                            font-size: 1.2em;
                        }
                        .huge {
                            font-size: 1.4em;
                        }

                        .poiLabel {
                            margin-left: 1em;
                        }

                        .poiLabelPos {
                            cursor: pointer;
                            margin: 0 0.2em;
                        }

                        .rotate45 {
                            transform: rotate(45deg);
                        }
                    }
                }

                &.secondaryHighlight {
                    background: #baedff;
                }
            }

            .buttonPanelRight {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .combinedGroupTrash, .combinedGroupCopy, .combinedGroupMagic {
                    cursor: pointer;
                    background: #ffbc8f;
                    color: darkred;
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                }

                .combinedGroupTrash {
                    min-height: 50px;
                    margin: 0 0 0.3em 0;
                }

                .combinedGroupCopy {
                    flex: 6;
                    background: #b8ffc1;
                    color: #080061;
                    margin: 0.3em 0 0 0;
                }

                .combinedGroupMagic {
                    min-height: 50px;
                    background: #b8e6ff;
                    color: #080061;
                    margin: 0.3em 0 0 0;
                }
            }
        }
    }
}

.availableArea {
    position: relative;

    .searchRow {
        .hidden {
            //display: none; // TODO!
        }
        display: flex;
        align-items: center;

        .search {
            width: 40em;
        }

        .engine {
            width: 30px;
            height: 30px;
            border: 1px solid transparent;

            &.google {
                background: url(//upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg) no-repeat center
                    center;
                background-size: contain;
            }

            &.osm {
                background: url(//upload.wikimedia.org/wikipedia/commons/b/b0/Openstreetmap_logo.svg)
                    no-repeat center center;
                background-size: contain;
            }

            &[data-selected='true'] {
                border: 1px solid black;
            }
        }

        &.coordinatesSearchRow {
            > * {
                margin: 0 1em;
                font-weight: bold;
            }
        }
    }

    .results {
        .groupHeader {
            margin: 3px 0;
        }

        .filterSearchBox {
            position: relative;

            .eye {
                position: absolute;
                left: 5px;
                top: 6px;
                font-size: 12px;
            }
        }
        .resultGroup {
            font-weight: bold;
            font-size: 1.3em;
            &:not(.expanded):before {
                content: '► ';
            }
            &.expanded:before {
                content: '▼ ';
            }
        }

        .hasInfo {
            color: darkgreen;
        }
    }

    .loadingOverlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.4);

        div {
            i {
                color: white;
                margin-right: 0.5em;
            }

            span {
                font-weight: bold;
                color: white;
                font-size: 3em;
            }
        }
    }
}

i.inline {
    margin-left: 1em;
}

.pointer {
    cursor: pointer;
    user-select: none;
}

.bottomIcon {
    display: block;
    margin-top: 0.5em;
    padding: 0.3em;
}

.addPartRow >* {
    margin-right: 1em;
}

.flagRow >* {
    margin-right: 2em;
}

.disabled {
    color: gray;
    user-select: none;
}

.customFlags {
    display: flex;
    font-size: 12px;
    margin-top: 1em;

    .checkbox {
        margin-left: 0.5em;
        margin-right: 0.5em;
        font-weight: bold;
    }
}

.clickable {
    pointer-events: auto;
    cursor: pointer;
}

.flagAndCross {
    position: relative;

    .crossoutFlag {
        color: red;
        position: absolute;
        right: 4px;
        transform: scale(1.2);
        text-shadow: 1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white;
    }
}

.strikethrough {
    text-decoration: line-through;
}

.genericPropertyButton {
    font-size: 0.8em;
    font-family: monospace;
    font-weight: bold;
}

.flex {
    display: flex;
}

.flexVertical {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.flexHorizontal {
    display: flex;
}

.flexCenter {
    align-items: center;
}

.divider {
    width: 1px;
    background-color: black;
    margin: 0 2px;
    height: 22px;
    color: black;
}

.toggleBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    height: 22px;
    padding: 4px;
    border: 1px solid black;
    color: white;
    font-weight: bold;
    background-color: rgba(100, 100, 100, 0.8);
    i {
        margin-right: 0.3em;
    }
    &.selected {
        background-color: rgba(200, 200, 200, 0.8);
        color: black;
    }
}

.mapExplainer {
    width: 100%;
    color: black;
    background-color: lightblue;
}

.coordsOverlap {
    display: flex;
    width: 1100px;
    justify-content: center;

    &.explainerImages {
        .between {
            flex: 1;
        }
        .image {
            cursor: pointer;
            display: block;
            height: 124px;
            width: 200px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .first {
            background-image: url('https://published.unitedrobots.se/graphics/geo-setup/1-sharing-any-point.png');
        }
        .second {
            background-image: url('https://published.unitedrobots.se/graphics/geo-setup/2-at-least-10-pct-contained.png');
        }
        .third {
            background-image: url('https://published.unitedrobots.se/graphics/geo-setup/3-majority-contained.png');
        }
        .fourth {
            background-image: url('https://published.unitedrobots.se/graphics/geo-setup/4-almost-fully-contained.png');
        }
        .fifth {
            background-image: url('https://published.unitedrobots.se/graphics/geo-setup/5-fully-inside.png');
        }
    }

    > input {
        display: block;
        margin: 0 100px;
        flex: 1;
    }
}

.addAll {
    margin-left: 1em;
    cursor: pointer;
}

.colorVariantBox {
    padding: 0.3em;
    background: white;
    font-size: 1.2em;
    font-weight: bold;

    .rainbow {
        background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .singleColor {
        color: blue;
    }
}
