.container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 55px);
}
.showFilters {
    margin-top: 240px;
    overflow-y: auto;
}

.hideFilters {
    margin-top: 120px;
    overflow-y: auto;
}
