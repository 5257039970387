.wrapper {
  display: block;
  padding: 1em;
  width: 100%;

  .header {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .subHeader {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .collectionTaskInfo {
    position: relative;
    margin: 0.5em 1em;
    padding: 0.5em;

    &:nth-of-type(2n) {
      background-color: lightgrey;
    }

    .row {
      display: block;
      height: 1.4em;
      line-height: 1.4em;

      span:first-of-type {
        display: inline-block;
        font-weight: bold;
        width: 15em;
        margin-right: 1em;
      }
    }

    .icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 1em;
      height: 1em;

      &.fa-check-circle {
        color: green;
      }
      &.fa-times-circle {
        color: red;
      }
      &.fa-hourglass {
        color: #ff8f00;
      }
      &.fa-question-circle {
        color: darkgrey;
      }
    }
  }

  .inputRow {
    label {
      display: flex;
      align-items: center;

      .input {
        margin-left: 1em;
        width: 200px;
      }

      .explainTime {
        margin-left: 1em;
        color: darkgrey;
      }

      .errorExplain {
        margin-left: 1em;
        color: darkred;
      }

    }
    .inlineButton {
      padding-left: 2em;
      font-size: 0.9em;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .remove {
    color: darkred;
    margin: 0 1em;
    cursor: pointer;
  }
}

