.fixedHeaderSticky {
    position: sticky;
    top: 0;
}

.headerBig {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 6px 0;
    text-decoration: underline;
}

.headerMedium {
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0 6px 0;
}

.headerSmall {
    font-size: 15px;
    font-weight: bold;
    margin: 8px 0 6px 0;
}

.smallerText {
    font-size: 13px;
}

.notActive {
    color: darkgray;
}

.inputLine {
    > span {
        margin-right: 1em;
        display: inline-block;
    }

    .label {
        font-weight: bold;
    }

    input[type='number'] {
        width: 3em;
    }
}

.subArea {
    margin-left: 1em;
    border-left: 5px solid #737dff;
    padding-left: 1em;
}

.subExplanation {
    font-size: 13px;
    margin-left: 1em;
    border: 1px dashed #ffe5cd;
    background: #f8ffea;
    padding: 0.5em;
    max-width: 500px;
}

.subExplanation div:not(:first-of-type) {
    font-size: 12px;
}

.subExplanationFrequency:before {
    content: 'Frekvens: ';
    font-weight: normal;
}

.subExplanationRecommendation:before {
    content: 'Rekommendation: ';
    font-weight: normal;
}

.subExplanationFrequency,
.subExplanationRecommendation {
    margin: 0.5em;
    font-weight: bold;
}

.subExplanationRecommendationText {
    margin-left: 0.5em;
}

.scopeWrapper {
    font-size: 15px;
    margin: 1em 0;
}

.scopeWrapperOr {
    margin: 0.5em;
    font-size: 13px;
}

.scopeRow {
    display: flex;
}

.scopeOne {
    display: flex;

    div:not(:first-of-type):before {
        content: 'och';
        margin: 0 1em;
        font-size: 13px;
        font-weight: normal;
        border: none;
    }
}

.scopeItem {
    font-weight: bold;
}

.scopeTrash {
    margin-left: 1em;
    color: darkred;
    cursor: pointer;
}

.scopeAddRow {
    color: #737dff;
    text-decoration: underline;
    cursor: pointer;
}

.scopeAdd {
    margin-right: 1em;
}

.scopeDropdown {
    margin-top: 0.5em;
    display: block;
}

.horizontal {
    display: flex;

    label:not(:first-of-type) {
        margin-left: 2em;
    }
}

.smaller {
    margin-left: 2em;

    label,
    span {
        font-size: 10px;
    }

    label > select {
        margin-left: 1em;
    }

    .horizontal {
        align-items: center;

        .vr {
            margin-left: 2em;
        }
    }
}

.tooltip {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    margin-left: 0.5em;
    color: #8888ff;

    &.warn {
            color: #ff0000;
    }
}

.tooltipsmaller {
    font-size: 14px;
    color: #7b7b7b;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgba(80, 80, 80, 0.8);
    color: #fff;
    text-align: center;
    padding: 0.7em 1em;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 1.5em;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.quarterHr {
    min-width: 500px;
    width: 50%;
    margin: 0;
}

.addNewSection {
    background-color: lightgrey;
    padding: 0.5em 1em 1em 1em;
}

.summaryRow {
    display: flex;
    background-color: #b1b1b1;
    font-weight: bold;
    padding: 1em;
    align-items: center;

    &:nth-of-type(2n-1) {
        background-color: #868686;
    }

    .summaryCol {
        flex: 1;
    }
}

.warnSection {
    padding: 0.5em 1em;
    margin-top: 2em;
    background-color: rgba(255, 0, 0, 0.2);

    hr {
        background: rgba(0, 0, 0, 0.2);
    }

    input {
        min-width: 30em;
    }

    .confirmButton {
        margin-top: 1em;
    }
}

.lagfartPrintList {
    width: 300px;
    display: inline-block;
}

.warn {
    color: #ff0000;
    &:hover {
        background-color: yellow;
    }
}

.tableRoot {
    margin-top: 5px;
    padding: 5px;

    border-collapse: collapse;
    font-family: sans-serif;
    font-size: 0.8rem;
    & tr {
        &:nth-child(odd) {
            background: #EEE;
        }
        & th {
            padding: 5px;
            border: 1px solid #bebebe;
            min-width: 200px;
            &:first-of-type {
                min-width: 200px;

            }
            &:last-of-type {
                min-width: 50px;
            }
        }
        & td {
            padding: 5px;
            border: 1px solid #bebebe;
        }
    }
}

.onRow {
    display: flex;
    align-items: center;
    > * {
        margin: 6px 6px;
    }
}

.regionSelect {
    width: 300px;
    display: inline-block;
}