.pageTitle {
    padding: 10px;

    &__top {
        display: flex;
        align-items: center;

        &-left {
            flex: 1 1 100%;
            h1 {
                margin: 0;
                font-size: 20px;
            }
        }

        &-right {
            display: flex;
            flex: 1 1 100%;
            justify-content: flex-end;
        }
    }

    &__bottom {
        margin: 5px 0 0 0;
    }
}
