.articleContainer {
}

.articleHeader {
}

.itemButtons {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    min-width: 440px;
    a {
        background: #9098a9;

        &:hover {
            background-color: lighten(#9098a9, 5);
        }
    }
}

.bookmarkHeaderContent .itemButtons {
    a {
        background: #ecaa6f;

        &:hover {
            background-color: lighten(#ecaa6f, 5);
        }
    }
}

.articleHeaderContent {
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0;
    font-size: 14px;
    background: #7c8494;
    padding: 10px;
    color: #fff;
}

.bookmarkHeaderContent {
    background-color: #f5962a;
}

.articleTags {
    background: #fff;
    padding: 5px 5px 0;
}

.articleTag {
    display: inline-block;
    font-size: 11px;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 0 8px 4px 0;
}

.articleContent {
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.articleLineOne {
    font-weight: bold;

    .bookmark {
        margin-right: 0.5em;
    }
}

.articleLineTwo {
    font-style: italic;
}

.generatedText {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.3;
}

.noText {
    font-size: 14px;
    color: #999;
}

.generatedTextParagraph {
    border-top: 1px dashed #aaa;
    padding: 10px 0;
    margin: 0;
    font-size: 14px;

    &:last-child {
        padding: 10px 0 0 0;
    }
}

.generatedTextContainer {
    padding: 15px;
    border: 1px solid #d2d9e4;
    background: #e7ebf1;
}

.infoboxBlock {
    border: 1px solid #bb9966;
    padding: 1em;
    margin: 1em;
    width: 50%;
    max-width: 500px;

    .infoboxLine {
        &:first-of-type {
            font-weight: bold;
        }
    }
}

.embedImagePreview {
    background-size: cover;
    height: 120px;
    width: 120px;
    border: 1px solid gray;
}

.embedImageRow {
    display: flex;
    margin-right: 0.5em;

    a:not(:first-of-type) {
        margin-left: 5px;
    }
}

.trigger {
    font-size: 12px;
    display: inline-block;
    border-radius: 8px;
    background-color: black;
    color: white;
    margin: 0.2em 1em;
    padding: 5px 10px;
}
