@import '../../../../../../../styles/base';

.nav_oneOf {
    color: $color-oneOf;
}
.nav_firstOf {
    color: $color-firstOf;
}
.nav_data {
    color: $color-data;
}
.nav_all {
    color: $color-all;
}
.nav_listOf {
    color: $color-listOf;
}

.nav_reuse {
    color: $color-reuse;
}
.nav_reuseOrange {
    color: $color-reuseOrange;
}
.nav_fail {
    color: red;
}
.nav_lang {
    color: $color-lang;
}

.disabled {
    text-decoration: line-through;
}
