.wrapper {
    margin: 0 1em;
}

.filters {
    position: sticky;
    background-color: #e7ebf1;
    top: 0;
    width: 100%;
    height: 3em;
    display: inline-block;
    z-index: 1000;
    box-shadow: 0 10px 5px -6px #999;

    > * {
        display: inherit;
    }
}

select {
    display: inline-block;
    margin: 0.5em;
}

.menuButton {
    padding-left: 10px
}