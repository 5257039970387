.contextUsed {
    position: relative;
    /* left: -20px; */
    // top: 7px;
    width: 8px;
    font-size: 8px;
    margin-left: -8px;
}
.contextContainer {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.contextEditorContainer {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.contextInput {
    background-color: #cccccc;
    flex: 1;
    margin-right: 8px;
}
.contextInput_selected {
    @extend .contextInput;
    background-color: #65ccb2;
}

.propertyContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:only-child {
        margin-bottom: 0;
    }

    > div {
        &:first-of-type {
            min-width: 160px;
            margin-right: 5px;
        }
    }

    & input {
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #ccc;
        width: 100%;
    }

    & textarea {
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #ccc;
        width: 100%;
    }
}

.inputContainer {
    display: flex;
    padding: 10px 0 0;

    > div {
        flex: 0 0 50%;

        &:first-of-type {
            padding-right: 10px;
        }

        &:last-of-type {
            padding-left: 10px;
        }

        > div > div {
            &:last-of-type {
                flex: 1;
            }
        }
    }
}

.conditionContainer {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ccc;

    > div > div {
        &:first-of-type {
            min-width: 160px;
            margin-right: 5px;
        }

        &:last-of-type {
            flex: 1;
        }
    }
}
