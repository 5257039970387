:global {
    .api_texts_filter {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 20px;
        .advanced-select {
            width: 150px;
            margin-left: 5px;

            &__menu {
                z-index: 3000 !important;
            }
        }
    }
}
