.header {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.leagueHeader {
    flex: 1 1 auto;
}

.leagueName {
    font-weight: bold;
    margin-right: 10px;
}

.leagueDate {
    font-size: 14px;
    font-weight: normal;
}

.eventName {
    font-size: 16px;
}

.highlightedTeam {
    background: rgba(100, 150, 100, 0.5) !important;
}

.highlightedIcon {
    margin-right: 5px;
}
