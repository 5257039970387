.errorBox {
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 0.5em;
    margin-bottom: 0.5em;
    background-color: #f5dfdf;

    .title {
        color: red;
    }

    p {
        color: black;
        margin-left: 0.5em;
    }
}

details {
    cursor: pointer;
    summary {
        display: list-item;
        padding: 0.5em;
        > div {
            display: inline-block;
        }
    }
}

details[open] {
    > div {
        color: black;
        cursor: text;
        background-color: white;
        border-radius: 4px;
        padding: 0.5em;
    }
    summary {
        margin-bottom: 0.5em;
    }
}
