.container {
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid #CECECE;
}

.note {
  border: 1px dashed rgba(0, 0, 0, 0.3);
  background-color: lightyellow;
  padding: 0.5em;
  margin: 0.5em 0;
  max-width: 500px;
}

.loadSaveBtn {
  margin: 3px 1em 3px 0;
}

.subcontainer {
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.subcontainer:nth-of-type(2n+1) {
  background-color: rgb(0, 0, 0, 0.05);
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 4px 0 10px 0;
}

.marginLeftRadio {
  margin-left: 2em;
}

.text, .textArea {
  width: 40em;
  padding: 7px 13px;
}

.textArea {
  height: 7em;
}

.addBtn {
  margin-top: 1em;
}