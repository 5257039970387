.notificationMessageContainer {
    position: absolute;
    bottom: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10000;
}

.notificationMessage {
    background: #65ccb2;
    padding: 10px;
    color: white;
}

.aggregatesList {
    margin-bottom: 20px;
}

.aggregatesListHeader {
    display: flex;
    align-items: center;
    font-weight: bold;
    background: #e7ebf1;
    padding: 10px;

    &Left {
        flex: 1 1 100%;
    }
    &Right {
        flex: 1 1 100%;
        text-align: right;
    }
}

.aggregateButtons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}
