ModalContent {
    background-color: lightgray;
}
.alert {
    margin-top: 10px;
    max-width: fit-content;
}
.inputFields {
    margin-top: 10px;
    min-height: 100px;
}

.movingIndicator {
    padding-top: 2px;
    display: inline-block;
    vertical-align: top;
}


td {
    min-width: 100px;
}