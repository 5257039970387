.searchResultSection {
    padding: 10px;
}

.searchResultTitleRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchResultTitle {
        font-size: 1.3em;
        flex: 1;
    }

    .toggleCurrentSubpath {
        margin-right: 1em;
        cursor: pointer;

        input[type='checkbox'] {
            display: inline-block;
            margin-right: 0.5em;
        }
    }
}

.searchResult {
    padding: 1em 1em 0.2em;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -3px 5px rgba(1, 1, 1, 0.2);
    border-bottom: 1px solid rgba(1, 1, 1, 0.2);

    .close {
        cursor: pointer;
    }

    .searchResultContainer {
        max-height: 300px;
        overflow-y: auto;
    }
    .selected {
        background-color: #efefef;
    }
    .searchResultContent {
        margin: 1em 0;
        flex: 1;
        width: 100%;

        tr:nth-child(even) {
            background-color: #f3f3f3;
        }

        .oneSearchResult {
            padding: 0.25em 0;
            width: 100%;
            height: 2em;

            td {
                padding-right: 1.5rem;
            }
            .type {
                width: auto;
                text-align: right;
            }
            .index {
                // min-width: 3em;
                color: darkgray;
            }
            .value {
                color: #4ba3f3;
                cursor: pointer;
            }

            .value:hover {
                text-decoration: underline;
            }

            .label {
                color: white;
                padding: 2px 10px;
                border-radius: 100px;
                background-color: rgba(1, 1, 1, 0.5);
                &.data {
                    background-color: #d29b00;
                }
                &.condition {
                    background-color: #0b97c4;
                }
                &.alternative {
                    background-color: #08c428;
                }
                &.list {
                    background-color: #dc1c75;
                }
                &.description {
                    background-color: grey;
                }
                &.reuse_node {
                    background-color: #6362df;
                }
                &.reuse {
                    background-color: #0d0c5e;
                }
                &.lang {
                    background-color: rgba(1, 1, 1, 0.5);
                }
                &.inputs {
                    background-color: rgba(1, 1, 1, 0.5);
                }
                &.text {
                    background-color: rgba(1, 1, 1, 0.5);
                }
                &.aggregates {
                    background-color: rgba(1, 1, 1, 0.5);
                }
            }

            .showUsages {
                cursor: pointer;
            }
        }
    }
}
