@import '../../../../../../../styles/base';

$footerHeight: 50px;
$searchHeight: 300px;

.container {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.preview {
    display: flex;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: white;
    color: #333;
    //height: 55px;
    height: 100%;
    padding: 20px;
    z-index: 1000;
    border-bottom: 1px solid #eee;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}
.preview_paragraph {
    padding-top: 5px;
    border-top: 1px dashed #aaa;
}
.preview_meta {
    margin-top: 5px;
    padding: 4px;
    border: 1px solid #969696;
    background-color: #bfbfbf;
    border-radius: 5px;
}

.explore {
    display: flex;
    //align-items: ;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: white;
    color: #333;
    //height: 55px;
    min-height: 300px;
    max-height: 300px;
    padding: 20px;
    z-index: 1000;
    overflow: auto;
    border-bottom: 1px solid #eee;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.buttonBar {
    background: #fff;
    min-height: 55px;
    max-height: 300px;
    padding: 0 20px;
    z-index: 500;
    //text-align: right;
    display: flex;
    align-items: center;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    box-shadow: 0 2px 0 rgb(215, 220, 228);
}

.breadCrumbs {
    flex: 1;
}

.buttonContainer {
    display: flex;
}

.content {
    padding: 0 $bigPaddingLeft ($footerHeight + 10);
    flex: 1;
    overflow: auto;
}

.withSearch {
    padding: 0 $bigPaddingLeft ($footerHeight + $searchHeight + 10);
}

.button {
    background-color: white;
    text-transform: none;
    padding: 0 8px;
    font-size: 15px;
    height: 36px;
    margin: 8px 0 8px 4px;
    cursor: pointer;

    $colors: (
        data: $color-data,
        data-darker: $color-data-darker,
        paragraph: $color-paragraph,
        paragraph-darker: $color-paragraph-darker,
        firstOf: $color-firstOf,
        firstOf-darker: $color-firstOf-darker,
        oneOf: $color-oneOf,
        oneOf-darker: $color-oneOf-darker,
        listOf: $color-listOf,
        listOf-darker: $color-listOf-darker,
        reuse: $color-reuse,
        reuse-darker: $color-reuse-darker,
        lang: $color-lang,
        lang-darker: $color-lang-darker,
        flag: $color-flag,
        flag-darker: $color-flag-darker,
    );

    $classes: data, paragraph, firstOf, oneOf, listOf, reuse, lang, flag;

    @mixin button_creator($color) {
        color: #fff;
        border: none;
        background: map-get($colors, $color);

        &:hover {
            background: map-get($colors, $color + '-darker');
        }

        &.disabled {
            color: #d3d3d3;
            background: rgba(1, 1, 1, 0.5);
        }
    }

    @each $class in $classes {
        &.#{$class} {
            @extend .button;
            @include button_creator($class);
        }
    }
}

.notImplementedLang {
    opacity: 0.4;
}

.noMargin {
    margin: 0;
}

.editorItem {
    // background: red;
}

.selectedLanguage {
    //border: 1px solid black;
    // box-shadow: 3px 3px 3px grey;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.editorHead {
    background: #7c8494;
    margin-top: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    flex: 1;

    a {
        height: 32px;
        margin-left: 5px;
    }
}

.editorHeadItems {
    display: flex;
    //justify-content: flex-end;
    //padding: 5px 10px;
}

.aboutMarker:before {
    display: block;
    position: relative;
    content: '';
    padding-right: 1px;
    margin-left: 1px;
    outline: solid #ffeb3b;
    width: 1px;
    top: 2px;
}

.editorHeadHead {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 5px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    a {
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        border: none;

        &:hover {
            background: rgba(0, 0, 0, 0.2);
        }

        i[class*='fa-binoculars'] {
            font-size: 14px;
        }

        &[class*='warn'] {
            border: 2px solid #ff8522;
        }

        &[class*='notSelected'] {
        }

        &[class*='selected'] {
            border: 2px solid #08c472;
        }

        &[class*='remove'] {
            background: #983842;

            &:hover {
                background: #ae404c;
            }
        }
    }
}
.editorHeadHeadFirstValid {
    @extend .editorHeadHead;
    background-color: #b0e0b2;
}
.editorHeadHeadDisabled {
    @extend .editorHeadHead;
    //background-color: #b0e0b2;
}
.editorHeadHeadValid {
    @extend .editorHeadHead;
    background-color: #80aa82;
}
.editorHeadHeadNotValid {
    @extend .editorHeadHead;
    background-color: #d27c85;
    color: #fff;
}

.editorHeadHeadBranchFailed {
    @extend .editorHeadHead;
    background-color: #d6a082;
}

.editorHeadBody {
    background: #fff;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
.editorBody {
    padding: 10px;
    // border: 1px solid #eee;
    background: #fff;
    // margin-bottom: 10px;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.textContentBody {
    @extend .editorBody;
    background-color: #dedede;
    & a {
        text-decoration: underline;
        white-space: pre-line;
    }
}
.textContentBodyError {
    @extend .textContentBody;
    //background-color: #d6bbbb;
    & * {
        color: #610000;
    }
}

.dataBox {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    margin-bottom: 10px;
}

.dataDesc {
    font-weight: bold;
}
.dataSubDesc {
    font-style: italic;
    font-size: 12px;
}

.topPropertiesContainer {
    background: #fff;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    border-radius: 4px;
}

.topPropertiesTitle {
    //background-color: #0b97c4;
    //font-weight: bold;
}

.rootBox {
    background: #fff;
    box-shadow: 0 2px 0 rgb(215, 220, 228);
    border-radius: 4px;
    padding: 10px;
}

.prioList {
    background-color: #aaa;
    padding: 4px;
    font-weight: bold;
    color: black;
    cursor: pointer;
}

.prioList:hover {
    opacity: 0.75;
}

.prioList_disabled {
    @extend .prioList;
    background-color: grey;
}
.prioList_firstValid {
    @extend .prioList;
    background-color: #b0e0b2;
}
.prioList_valid {
    @extend .prioList;
    background-color: #80aa82;
}
.prioList_notValid {
    @extend .prioList;
    background-color: #cc3d4c;
    color: #fff;
}

.datalist {
    border: 1px solid #999;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:hover {
        background-color: #ccc;
        cursor: pointer;
    }
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    white-space: pre-line;
}

.saveByName {
    display: flex;
    align-items: center;
    position: fixed;
    width: inherit;
    left: $sideBarWidth;
    right: 0;
    bottom: 0;
    background: #fff;
    min-height: $footerHeight;
    padding-bottom: 1em;
    z-index: 1000;
}

.copyPasteSection {
    margin-top: 20px;
    padding-bottom: 20px;
}

.overviewDescription {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.betweenActionButtons {
    padding: 5px 0;
    margin: 5px 0;

    a {
        height: 24px;
        background: $primary-color;

        &:hover {
            background: $primary-color-darker;
        }
    }
}

.markHeader {
    display: flex;
    justify-content: flex-end;
}

/* Dropdown Button */
.dropbtn {
    background-color: #4caf50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    //min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    *:hover {
        background-color: #ddd;
    }

    * {
        color: black;
        padding: 0 16px;
        text-decoration: none;
        display: block;
    }
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
