.container {
    padding-left: 1em;
    border-left: 5px solid lightblue;
    display: block;
    margin: 0.5em 0;

    .headerSmall {
        font-size: 15px;
        font-weight: bold;
        margin: 8px 0 6px 0;
    }

    .headerSmaller {
        font-size: 110%;
        font-weight: bold;
        text-decoration: underline;
        margin: 6px 0 4px 0;
    }

    .note {
        background-color: lightyellow;
        border: 1px dashed #ffffc2;
        width: 500px;
        padding: 0.5em;
        margin: 0.5em;
    }

    .formRow {
        display: flex;
        align-items: center;

        >*:last-of-type {
            flex: 100%;
            max-width: 600px;
        }

        .label {
            font-size: 14px;
            font-weight: bold;
            margin-right: 1em;
        }
    }

    .weekdays {
        display: flex;
        align-items: center;
        user-select: none;

        .weekdayItemWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #8e8e8e;

            &.selected {
                font-weight: bold;
                color: black;

                .weekday {
                    border: 2px solid black;
                }
            }

            .weekday {
                display: inline-flex;
                border-radius: 2em;
                font-size: 16px;
                background-color: #dedede;
                width: 2.5em;
                height: 2.5em;
                margin: 0.5em 1em;
                align-items: center;
                justify-content: center;

                &.weekday1,
                &.weekday2,
                &.weekday3,
                &.weekday4,
                &.weekday5 {}

                &.weekday6,
                &.weekday7 {
                    background-color: #ff8e8e;
                }
            }

            .clockWrapper {
                cursor: pointer;

                .clockPlus {
                    margin-left: 3px;
                    font-size: 90%;
                }
            }
        }

        .repeatAmount {
            display: flex;
            align-items: center;
        }
    }

    .timeIntervals {
        margin: 0.5em 0;

        .timeInterval {
            padding: 0.75em;
            display: flex;
            align-items: center;

            .timeIntervalWeekday {
                margin: 0 6px;
                padding: 2px;
                display: block;
                font-size: 11px;
                font-family: monospace;
                font-weight: bold;
                color: gray;
                background-color: darkgrey;
                cursor: pointer;

                &.selected {
                    color: white;
                    background-color: black;
                }
            }
        }

        .time {
            margin-left: 1em;

            .timeLabel {
                display: flex;
                align-items: center;
                >* {
                    margin: 0 1em;
                }
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

.disabled {
    opacity: 0.2;
    user-select: none;
    cursor: default;
}

.margin {
    margin: 0.5em 0;
}

.marginW {
    margin: 0 0.5em;
}

.copyPaste {
    margin: 4px 8px;
    font-size: 1.2em;
}