.pageWrapper {
    .categoryHeader {
        margin: 1em;

        .orange {
            color: orange;
        }

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }
    }
}

.mapAndResult {
    display: flex;

    .map {
        width: 860px;
        height: 640px;
    }

    .result {
        padding-left: 1em;
        flex: 1;
    }
}

.flex {
    display: flex;
    align-items: center;
    b {
        margin-left: 0.3em;
    }
}

.desoOrSams,
.similarOrNeighbor,
.biggerRegion {
    display: block;
    width: 1em;
    margin: 0 0.3em;
}

.desoOrSams {
    background-color: blue;
}

.similarOrNeighbor {
    background-color: lightgreen;
}

.biggerRegion {
    background-color: lightgrey;
}

.explanation {
    font-style: italic;
    margin: 0.3em 0;
}

.form {
    label {
        display: flex;
        align-items: center;

        &.grayout {
            div {
                color: darkgrey;
            }

            input {
                background-color: lightgrey;
            }
        }

        div {
            font-weight: bold;
            width: 100px;
        }

        input {
            width: 30em;
        }
    }
}

.estimationTypeDeso,
.estimationTypeSams,
.estimationTypeSimilarDeso,
.estimationTypeNeighborSams,
.estimationTypeCity,
.estimationTypeMunicipality {
    margin-top: 1em;
    padding: 0.3em;
    border-width: 3px;
    border-style: solid;

    &:not(.active) {
        opacity: 0.5;
        border-width: 1px;
    }
}

.estimationTypeDeso {
    background-color: lightblue;
    border-color: blue;
}

.estimationTypeSams {
    background-color: yellow;
    border-color: #37ff00;
}

.estimationTypeSimilarDeso {
    background-color: lightgreen;
    border-color: green;
}

.estimationTypeNeighborSams {
    background-color: #a47aa4;
    border-color: magenta;
}

.estimationTypeCity {
    background-color: orange;
    border-color: red;
}

.estimationTypeMunicipality {
    background-color: sandybrown;
    border-color: saddlebrown;
}

.inline {
    display: inline-flex;
}
