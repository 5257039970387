@import '../../../../../styles/base';

.switch {
}

.switchItem {
    color: white;
    display: block;
    font-size: 15px;
    padding: 4px;
    padding-left: 15px;
}

.switchTitle {
    //font-weight:bold;
    background-color: #2e333e;
    border-bottom: black;
    //margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
}

.switchItemSelected {
    @extend .switchItem;
    font-weight: bold;
    // background-color: #274f52;
}

.settingsContainer {
    background: $sidebarColor-lighter;
    overflow: auto;
    height: 200px;
}

.header {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    padding: 6px 12px;
    margin-bottom: 4px;
    text-decoration: none;
    background-color: $sidebarColor-darker;
    //box-shadow: inset 4px 0 $primary-color;
    i {
        text-align: center;
        width: 20px;
        margin-right: 10px;
    }
}

.inputVariant {
    width: 8em;
}

.inputVariantAbbrev {
    width: 2em;
}
