@import '../../../../../../../../styles/base';

.subscription:nth-child(even) {
    background-color: #ccc;
}

.toomanycompanies {
    background-color: #f0000050;
}

.allLocations {
    background-color: yellow;
}

.taskBox {
    margin: 10px;
    .FAILED {
        background-color: red;
    }
    .RESCHEDULED {
        background-color: lavender;
    }
}

.parameters {
    color: blueviolet;
}

.timeOK {
    color: #04aa6d;
}
.timeNOK {
    color: #9c0031;
}