@import '../../../../../../../styles/base';

.smallButton {
    background-color: $primary-color;
    color: white;
    text-decoration: none;

    padding: 2px 20px;
    margin-left: 4px;

    &:hover {
        background-color: lighten($primary-color, 5);
        text-decoration: none;
    }

    &:active {
        background-color: darken($primary-color, 5);
        text-decoration: none;
    }
    &.selected {
        background-color: #08c472;
        &:hover {
            background-color: darken(#08c472, 10);
        }
    }
}

.comment {
    margin: 1px;
    padding: 10px;
    //border-radius: 10px;
    background: #fff1de;
}

.overview {
    display: inline-block;
    padding: 0 2px;

    text-decoration: none;
    background-color: #eeeeee;
    border-left: 1px solid #aaa;
}
.overview-data {
    @extend .overview;
    color: $color-data;
}
.overview-firstOf {
    @extend .overview;
    color: $color-firstOf;
}
.overview-reuse {
    @extend .overview;
    color: $color-reuse;
}
.overview-oneOf {
    @extend .overview;
    color: $color-oneOf;
}
.overview-listOf {
    @extend .overview;
    color: $color-listOf;
}
