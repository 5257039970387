@import '../../../../../../../styles/base';
$thisWidth: 230px;

.title {
    font-size: 22px;
    font-weight: bold;
}

.description {
    font-size: 12px;
    color: grey;
}

.itemContainer {
    width: 100%;
}

.selectedAggregate {
    background-color: lightgray;
}

.pageWrapper {
    .aggregateHeader {
        margin: 1em;
        display: flex;

        h2 {
            flex: 1;
        }

        .headerButtons {
            display: flex;
            flex-direction: row;
            align-items: center;

            .headerButton {
                margin-left: 1em;
            }
        }
    }
}
