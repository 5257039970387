.wrapper {
  display: block;
  padding: 1em;
  width: 100%;

  .header {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
  }

  .info {
    border: 1px dashed darkorange;
    background-color: lightyellow;
    font-style: italic;
    padding: 0.5em;
    margin: 0.5em;
  }

  .inputRow {
    label {
      font-size: 16px;
      display: flex;
      align-items: center;

      input {
        margin-left: 1em;
        width: 400px;
      }

      .explainTime {
        margin-left: 1em;
        color: darkgrey;
        font-style: italic;
        font-size: 0.9em;
      }
    }

    .keyValuePair {
      margin-left: 1em;
      width: 300px;
      height: 150px;
    }
  }

  .draggableButton {
    display: flex;
    width: 40px;
    justify-content: space-evenly;

    .draggable {
      cursor: move;
    }
  }

  .dropBetween {
    display: block;
    width: 100%;
    height: 1px;
    cursor: pointer;
    border-top: 4px dashed black;
  }

  .buttons {
    display: flex;
    width: 120px;
    justify-content: space-evenly;
  }

  .buttonsSpread {
    margin: 1em 0;
    display: flex;
    width: 300px;
    justify-content: space-evenly;
  }

  .entry {
    display: flex;
    align-items: center;
    width: 100%;

    &:nth-of-type(even) {
      background-color: #d9d9d9;
    }

    .data {
      margin: 0.5em 0;
      flex: 1;

      .value {
        font-weight: bold;
      }
    }
  }

  .matchingCompany {
    display: block;
    margin: 1em 0;

    .markMatch {
      background-color: yellow;
      font-weight: bold;
    }

    .markDisabled {
      color: darkgray;
      font-style: italic;
    }
  }

  .notInSync {
    background-color: #ffc7c7;
  }

  .approve {
    cursor: pointer;
    margin-left: 1em;
    color: darkgreen;
  }
}