.markContainer {
    display: flex;
}

.remove:hover {
    background-color: orangered;
}

.space {
    justify-content: space-between;
}

.container {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
}

.item {
    //flex: 0 50%;
    ////height: 100px;
    //margin-bottom: 2%; /* (100-32*3)/2 */
}
