.gesContainer {

  border: 1px solid lightgray;
  margin: 1em 0;
  padding: 0.5em;


  .titleWithRefresh {
    display: flex;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: bold;
      padding: 1em;
      padding-right: 2em;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .gesInstanceRow {
    display: flex;
    align-items: center;
    padding: 0.5em;
    border-bottom: 1px solid #dddddd;
    font-size: 15px;
    background-color: #ffffff;
    &:nth-child(2n) {
      background-color: #f8f8f8;
    }

    >* {
      margin: 0 0.5em;
    }

    .disabled {
      color: gray;
    }

    .connect, .instanceButton {
      display: flex;
      align-items: center;
      margin-left: 3em;

      &.selected {
        color: #ae91ff;
      }
    }
    .connect >* {
      margin-left: 6px;
    }
  }
}

.grid {
  display: flex;
  align-items: center;
  justify-items: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;

  .gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;

    .image {
      $width: calc(400px);
      width: $width;
      height: calc($width / (16/9));
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .label {
      font-weight: bold;
      margin: 0.5em 0;
    }
  }
}