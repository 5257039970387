@import '../../../../../../../../styles/base';

.token {
    text-decoration: none;
    padding-left: 1px;
    padding-right: 1px;
    background-color: #eeeeee;
    border-left: 1px solid #aaa;
    //border-right: 1px solid #ddd;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: $primary-color;
        background-color: #dddddd;
        //border-left: 1px solid #888;
        //border-right: 1px solid #888;
    }
}
.small {
    display: inline-block;
    min-width: 20px;
    text-align: center;
}

.token:hover {
}

.data {
    color: $color-data;
}

.firstOf {
    color: $color-firstOf;
}

.oneOf {
    color: $color-oneOf;
}

.listOf {
    color: $color-listOf;
}

.reuse {
    color: $color-reuse;
    background-color: #ffdfa3;
}

.error {
    color: #ad0029;
}

.empty {
    //color: #000000;
    //background: #ddd;
}

.wasEmpty {
    &:hover {
        color: black;
    }
    color: #bbb;
    //background: #ddd;
}
