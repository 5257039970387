.container {
    display: block;
    margin: 0.5em 0;

    .otherSettings {
        font-size: 16px;

        .label {
            font-weight: bold;
        }
    }
}