.calendar {
  position: relative;

  .week {
    border: 1px solid darkgrey;
    display: flex;
    width: 80%;
    max-width: 1000px;
    min-height: 2em;

    .weekNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid darkgray;
      width: 2em;
      font-size: 0.9em;
      font-weight: bold;
    }

    .dayHeader {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid darkgray;
    }

    .day {
      display: flex;
      flex-direction: column;
      color: darkgray;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-right: 1px solid darkgray;
      font-size: 1.2em;

      &.disabled {
        color: grey;
        background-color: lightgrey;
      }

      &.marked {
        color: darkgray;
        border: 2px solid black;
        border-radius: 1em;
      }

      &.active {
        color: black;
        border: 2px solid black;
      }

      &.withEvents {
        cursor: pointer;
      }

      .dayLabel {
        font-size: 12px;
      }

      .hours {
        display: block;
        width: 120px;
        height: 120px;

        .hour {
          display: flex;

          .minute {
            display: block;
            width: 2px;
            height: 5px;

            &.amountItems1 {
              background-color: #ffc12f;
            }
            &.amountItems2 {
              background-color: #ff8724;
            }
            &.amountItems3 {
              background-color: #ff4b00;
            }
            &.amountItems4 {
              background-color: #ff0000;
            }
            &.amountItems5 {
              background-color: #c40000;
            }
            &.amountItems6 {
              background-color: #7a0000;
            }
          }
        }

      }
    }
  }

  .info {
    border: 1px dotted darkgray;
    background-color: #fdfdec;
    padding: 6px;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 20%;
    max-width: 250px;
    align-items: center;
    height: 100%;
    justify-content: center;

    .header {
      display: block;
      width: 100%;
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
    }

    .items {
      display: block;
      width: 100%;
      flex: 1;
      overflow: scroll;

      .oneTimeInList {
        text-align: center;
      }
    }
  }
}