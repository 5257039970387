.pageWrapper {
    .categoryHeader {
        margin: 1em;

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }

        select {
            display: inline-block;
        }

        &.inline {
            display: flex;
        }
    }

    .resultContainer {
        margin: 1em;

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }

        .task {
            .green {
                color: darkgreen;
            }

            .distanceTop {
                margin-top: 0.5em;
            }

            &:not(:first-of-type) {
                margin-top: 1em;
                padding-top: 1em;
                border-top: 1px solid darkgray;

                &.taskFirstManual {
                    border-top: 5px dotted darkgrey;
                }
            }

            .label {
                display: inline-block;
                width: 5em;
            }

            .value {
                margin-left: 1em;
                font-weight: bold;

                .rerun,
                .rerunOtherInstance {
                    padding-left: 2em;
                    cursor: pointer;
                }

                .rerun {
                    color: darkorange;
                }

                .rerunOtherInstance {
                    color: darksalmon;
                }
            }

            .error {
                color: red;
            }

            .status {
                &[data-status='FAILED'] {
                    color: red;
                }
                &[data-status='COMPLETE'] {
                    color: darkgreen;
                }
                &[data-status='SCHEDULED'] {
                    color: orange;
                }
                &[data-status='RUNNING'] {
                    color: blue;
                }
            }

            .assigned {
                &[data-assigned='Unassigned'] {
                    color: lightgrey;

                    &:before,
                    &:after {
                        content: '-';
                    }
                }
                .prevFailAssigned {
                    margin-left: 1em;
                    color: #ffb477;
                }
            }

            .imageBlocks {
                display: flex;
                align-items: flex-end;
                > *:not(:last-of-type) {
                    margin-right: 1em;
                }

                .warnAltitude {
                    color: darkorange;
                    font-weight: bold;
                }

                .okAltitude {
                    color: darkgreen;
                    font-weight: bold;
                }

                .pointer {
                    cursor: pointer;
                }

                .videoPlaceholder {
                    width: 320px;
                    height: 240px;
                    border: 1px dashed darkgrey;
                    display: flex;
                    align-items: center;

                    i {
                        width: 100%;
                        text-align: center;
                    }
                }

                .chosenImage {
                    border: 3px solid black;
                    margin: 3px;
                    padding: 3px;
                }
            }
        }
    }
}
