.control {
    font-size: 16px;
    position: relative;
    display: block;
    margin: 12px 0;
    padding-left: 30px;
    cursor: pointer;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: #bebebe;
    border-radius: 5px;
}

.controlradio .control__indicator {
    border-radius: 50%;
}

/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
    background: #aaa;
}

/* Checked state */
.control input:checked ~ .control__indicator {
    background: #2aa1c0;
}

/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
    background: #0e647d;
}

/* Disabled state */
.control input:disabled ~ .control__indicator {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
}

/* Check mark */
.control__indicator:after {
    position: absolute;
    display: none;
    content: '';
}

/* Show check mark */
.control input:checked ~ .control__indicator:after {
    display: block;
}

/* Checkbox tick */
.controlcheckbox .control__indicator:after {
    top: 4px;
    left: 7px;
    width: 6px;
    height: 9px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 3px 3px 0;
}

/* Disabled tick colour */
.controlcheckbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
}

/* Radio button inner circle */
.controlradio .control__indicator:after {
    top: 7px;
    left: 7px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
}

/* Disabled circle colour */
.controlradio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
}

.control.small {
    padding-left: 20px;
    padding-top: 1px;
    margin: 0;
}

.small .control__indicator {
    width: 14px;
    height: 14px;
    border-radius: 3px;
}

.controlcheckbox.small .control__indicator:after {
    top: 3px;
    left: 5px;
    width: 4px;
    height: 7px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

.control.smaller {
    padding-left: 14px;
    padding-top: 1px;
    margin: 0;
    font-size: 12px;
}

.smaller .control__indicator {
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

.controlcheckbox.smaller .control__indicator:after {
    top: 2px;
    left: 4px;
    width: 4px;
    height: 7px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

.specialFlexCenterAlignOffset5 {
    display: flex;
    align-items: center;

    span {
        display: flex;
        align-items: center;
    }

    .control__indicator {
        top: 5px;
    }
}
