.bg {
  background-color: rgba(183, 197, 255, 0.7);
  padding: 0.5em 1em;
  border-left: 3px solid gray;
  margin: 1em;
}

.setting {
  display: flex;
  align-items: center;
  padding: 0.5em;

  .key {
    width: 15em;
  }

  .value {
    flex: 1;
    padding: 0.5em 1em;

    input {
      padding: 0.7em;
    }

    .input {
      width: 100%;
    }
  }

  .valuePart {
    display: inline-block;
    margin: 0.5em 1em;

    .remove {
      font-weight: bold;
      color: red;
      margin-left: 0.5em;
      cursor: pointer;
    }
  }

  .empty {
    color: darkgrey;
    font-style: italic;
  }

  .addCommunityPart {
    display: flex;

    .flex {
      flex: 1;
    }
  }
}

.communityList {
  padding-left: 0.5em;

  .communityListHeader {
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
  }

  .oneCommunity {
    display: flex;
    align-items: center;
    padding-top: 1em;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-top: 1em;
      border-top: 1px solid #8c8cbf;
    }

    &:last-of-type {
      padding-bottom: 1em;
    }

    .left {
      flex: 1;
    }

    .row {
      margin-top: 3px;
      display: flex;

      .name {
        color: #473838;
        font-weight: bold;
      }
      .state {
        margin: 0 1em;
        color: #473838;
        &:before {
          content: '/ ';
        }
      }
      .type {
        width: 150px;
      }
      .parts {
        margin: 0 1em;
        .part {
          &:not(:first-of-type) {
            &:before {
              margin: 0 1em;
              color: gray;
              content: '|';
            }
          }
        }
      }
    }

    .right {
      margin-right: 1em;
    }
  }
}
