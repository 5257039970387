.wrapper {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #caca86;
  background: #f1f19e;

  .label {
    margin-right: 1em;
    font-weight: bold;
  }
  .select {
    flex: 1;
  }
}