@import '../../../../styles/base';

$thisWidth: 390px;
.container {
    @extend .card;

    div[class*='generatedText'],
    div[class*='noText'] {
        margin: 10px 0 0 0;

        &:only-child {
            margin: 0;
        }
    }

    div[class*='generatedTextParagraph'] {
        margin: 0;
    }
}

.actionButton {
    margin-right: 5px;
}

.removeFromApprovedParent {
    position: relative;
    width: 43px;

    .removeFromApproved {
        content: 'X';
        color: red;
        font-weight: bold;
        font-size: 22px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        vertical-align: center;
        line-height: 30px;
        user-select: none;
        opacity: 0.7;
    }
}

.articleHeader {
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0;
    font-size: 14px;
    background: #7c8494;
    padding: 10px;
    color: #fff;
}

.articleContent {
    padding: 10px;
}

.failedTaskMessageLabel {
    padding-top: 10px;
    font-weight: bold;
}

.failedTaskMessageValue {
    margin: 8px 0;
    font-weight: bold;
    color: #494949;

    :first-child {
        font-style: italic;
        color: #828282;
        margin-right: 1em;
    }
}

.buttonHeader {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 3px;
}

.sidebar {
    position: fixed;
    top: $headerHeight;
    bottom: 0;
    margin-left: -$bigPaddingLeft;
    width: $thisWidth;
    padding: 20px $bigPaddingLeft;
    background-color: #fff;
    //border-right: 1px solid #000000;
    // box-shadow: 2px 0 4px rgba(0,0,0,.40);
    overflow: auto;
    z-index: 1000;
    color: #333;
    h1 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 0;
        a {
            color: #666;
        }
    }
}

.main {
    margin-left: $thisWidth;
}

.tasks {
    display: flex;
    align-items: center;
}

.status {
    margin-top: 10px;
}

.actions {
    display: flex;
    margin-top: 10px;
}

.streetviewActions,
.collageWrapper {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    &.imagesProviderWrapper {
        align-items: center;
    }

    .streetviewImage,
    .collageImage {
        border: 3px solid transparent;
        width: 250px;
        height: 175px;
        flex: 1;
        margin: 5px 5px 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        cursor: pointer;
        display: grid;
        place-items: center center;

        &.streetviewImageBest {
            cursor: default;
            border: 3px solid black;
        }

        &.streetviewImageDQ {
            cursor: default;
            opacity: 0.5;
        }

        &.streetviewLoading {
            opacity: 0.3;
            border: 3px solid transparent;

            &.streetviewImageWillBeBest {
                border: 3px solid black;
            }
        }
    }

    .collageImage {
        width: 600px;
        min-height: 420px;
    }

    .collageLabel {
        font-weight: bold;
        margin-right: 1em;
    }

    .streetviewRemove {
        color: gray;
        border: 1px solid lightgrey;
    }
}

.collageWrapper {
    flex-direction: column;
    align-content: center;
}

.flex {
    flex: 1;
}

.interlinking {
    display: flex;
    font-weight: bold;

    .resolved {
        color: #28a400;
    }
    .unresolved {
        color: #ff7676;
    }

    .link {
        margin-left: 1em;
        font-style: italic;
    }
}