.header {
    width: 100%;
    text-align: left;
}

.headerTop {
    margin-left: 5px;
    padding-top: 10px;
    display: flex;
}

.headerTitle {
    flex: 1 1 auto;
}

.headerHideShow {
    margin-left: 3px;
    padding: 6px 8px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    background: none;
}

.headerBottom {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    padding: 10px;
    background: #f8f8f8;
    margin-top: 10px;
    display: flex;
}

.headerBottomLeft {
    flex: 1 1 auto;
}

.listButtons2withQnA {
    display: flex;
    justify-content: flex-end;
    margin-right: 139px;
}

.listButtons1withQnA {
    display: flex;
    justify-content: flex-end;
    margin-right: 101px;
}

.listButtons0withQnA {
    display: flex;
    justify-content: flex-end;
    margin-right: 65px;
}

.listButtons2 {
    display: flex;
    justify-content: flex-end;
    margin-right: 74px;
}

.listButtons1 {
    display: flex;
    justify-content: flex-end;
    margin-right: 36px;
}

.listButtons0 {
    display: flex;
    justify-content: flex-end;
}

.statusButton {
    margin-right: 10px;
    font-size: 18px;
}

.hasChangedButton {
    margin-right: 10px;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.tag {
    color: #444;
    margin: 0 2px 0 0;
}
