.wrapper {
  display: block;
  padding: 1em;
  width: 100%;

  .header {
    font-size: 1.5em;
    font-weight: bold;
  }

  .info {
    font-style: italic;
  }

  .list {
    display: block;
    width: 100%;

    .row {
      display: flex;
      width: 100%;

      .expression {
        display: flex;
        flex: 1;

        input[type="text"] {
          flex: 1;
        }
      }

      >:not(.buttons) {
        flex: 1;
      }
    }
  }

  input:not([data-editing="true"]) {
    background-color: lightgrey;
    color: white;
  }

  input.disabled {
    cursor: not-allowed;
  }
}

