/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/

$primary-color: #4ba3f3;
$primary-color-darker: darken($primary-color, 10);
$primary-color-lighter: lighten($primary-color, 10);

$secondary-color: #4bf3a7;

$warn-color: #ff8522;
$warn-color-darker: darken($warn-color, 10);
$warn-color-lighter: lighten($warn-color, 10);

$color-all: #a328e0;
$color-data: #d29b00;
$color-paragraph: #9e9e9e;
$color-firstOf: #0b97c4;
$color-oneOf: #08c428;
$color-listOf: #dc1c75;
$color-reuse: #6362df;
$color-reuseOrange: #ff9239;
$color-lang: #d631ce;
$color-flag: #f0f0f0;

$color-data-darker: darken($color-data, 5);
$color-paragraph-darker: darken($color-paragraph, 5);
$color-firstOf-darker: darken($color-firstOf, 5);
$color-oneOf-darker: darken($color-oneOf, 5);
$color-listOf-darker: darken($color-listOf, 5);
$color-reuse-darker: darken($color-reuse, 5);
$color-lang-darker: darken($color-lang, 5);
$color-flag-darker: darken($color-flag, 5);

$color-delete: $primary-color;
$color-delete-inv: white;

$color-inactive: #aaa;
$color-inactive-inv: white;

$bigPaddingLeft: 20px;
$headerHeight: 55px;
$sideBarWidth: 180px;
$sidebarColor: #2e333e;
$sidebarColor-lighter: lighten($sidebarColor, 10);
$sidebarColor-darker: darken($sidebarColor, 10);

$inputColor: #d3d2d0;

.card {
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    // padding: 20px;
    // box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

.loading-box {
    padding: 15px;
}

.error {
    color: red;
}

.MuiTooltip-popper  {
    z-index: 9999999 !important;
}

.leafletjs-label {
    background-color: transparent;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 7px black;
}