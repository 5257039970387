.toastContainer {
    box-sizing: border-box;
    position: fixed;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
    width: 320px;
    padding: 0 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
    transition: opacity 0.13s ease-in;
    visibility: hidden;
    opacity: 0;
}

.toastContainerVisible {
    visibility: visible;
    opacity: 0.9;
}

.toast {
    box-sizing: border-box;
    padding: 10px 15px 10px 15px;
    border-radius: 7px;
    color: #fff;
    background-color: #ccc;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    text-align: center;
}

.toast:hover,
.toast:focus {
    opacity: 1;
}

.toastInfo {
    background-color: #4b351a;
}
