.pageWrapper {
    .categoryHeader {
        margin: 1em;
        display: flex;

        h2 {
            flex: 1;
        }
    }
}

.categoriesWrapper {
    margin: 0.5em;

    .content {
        background: #fff;
        padding-bottom: 10px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        .categoryDataContainer {
            margin: 0 1em;

            .error {
                margin: 1em;
                color: red;
                font-weight: bold;
            }
        }
    }

    .categoryRow {
        margin: 1em 0;
        padding: 1em 0.5em;
        line-height: 2em;
        background: #7c8494;
        color: white;
        display: flex;
        align-items: center;
        font-weight: bold;

        [type='checkbox'] {
            margin-right: 0.5em;
        }
    }

    .itemButtons {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex: 1;

        //a {
        //    background: #9098a9;
        //
        //    &:hover {
        //        background-color: lighten(#9098a9, 5);
        //    }
        //}
    }

    .plusButton {
        margin-top: 1em;
    }
}

.sampleCompany {
    margin: 0.5em;

    .sampleCompanyTitle {
        font-weight: bold;
    }

    .tooltipWord {
        background-color: lightgreen;
        text-decoration: underline;
    }
}

input[type='text'] {
    color: #000;
}
