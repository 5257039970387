.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;

    > li {
        display: inline;

        > a {
            position: relative;
            float: left;
            padding: 6px 12px;
            line-height: 1.42857143;
            text-decoration: none;
            color: $primary-color;
            background-color: #ffffff;
            border: 1px solid #dddddd;
            margin-left: -1px;
        }

        &:first-child > a {
            margin-left: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        &:last-child > a {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }

        > a:hover,
        > a:focus {
            z-index: 2;
            color: #23527c;
            background-color: #eeeeee;
            border-color: #dddddd;
        }
    }

    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
        z-index: 3;
        color: #ffffff;
        background-color: $primary-color;
        border-color: $primary-color;
        cursor: default;
    }

    > .disabled > a,
    > .disabled > a:hover,
    > .disabled > a:focus {
        color: #777777;
        background-color: #ffffff;
        border-color: #dddddd;
        cursor: not-allowed;
    }
}
