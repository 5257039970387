.page {
    margin: 1em;
}

.buttonBar {
    position: fixed;
    width: 100%;
    margin: -1em;
    padding-left: 1em;
    margin-bottom: 1em;
    background: #999999;
    z-index: 1000;
    > * {
        margin: 1em;
    }
}

.offsetButtonBar {
    padding-top: 4em;
}

.headerBig {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 6px 0;
    text-decoration: underline;
}

.subArea {
    margin-left: 1em;
    border-left: 5px solid #737dff;
    padding-left: 1em;
}
