@import '../../styles/base';

.textLink {
    color: black
}
.textLink:hover {
    color: white;
    background: #80aa82;
}

.textLinkError {
    color: #ff4f4f;
    background: #c0c0c0;
}

.textLinkData {
    color: $color-data;
    background: #dcdcdc;
}

.textLinkList {
    color: $color-listOf;
    background: #dcdcdc;
}

.textLinkPicked {
    background: #c4e1c5;
}