.printListEntry {
    margin: 0.5em 0 0.5em 6px;
    padding-left: 6px;
    border-left: 4px solid blue;
}

.title {
    font-weight: bold;
    font-size: 115%;
}

.note {
    border: 1px dashed rgba(0, 0, 0, 0.3);
    background-color: lightyellow;
    padding: 0.5em;
    margin: 0.5em 0;
    max-width: 500px;
}

.oneSetting {
    display: block;
    input[type='text'] {
        width: 100%;
    }
}

.linkStyle {
    margin: 0.5em;
    display: block;
    cursor: pointer;
    color: darkblue;
    text-decoration: underline;
}
