.wrapper {
    margin: 1em;

    .headerRow {
        font-weight: bold;
        font-size: 1.2em;
        margin: 1em 0;
    }

    .note {
        display: inline-block;
        padding: 0.5em 1em;
        background-color: #ffe184;
        border: 2px dashed #d9b400;
    }
}

.addWrapper {
    background-color: #c7e1ff;
    border: 1px solid rgba(0, 0, 1, 0.2);

    .headerRow {
        font-weight: bold;
        font-size: 1.1em;
        margin: 1em;
    }

    .label {
        margin: 0.5em 1em;
        display: flex;
        align-items: center;

        .labelText {
            font-weight: bold;
            width: 200px;
        }

        .valueInput {
            flex: 1;
            display: flex;
            align-items: center;

            > * {
              flex: 1;
            }

            input[type="date"] {
                max-width: 300px;
            }
        }

        .draggableColumn {
            border: 1px solid black;
            border-radius: 3px;
            margin: 0.5em;
            padding: 0.5em;
        }
    }

    .mergeWithLast {
        margin-top: -1em;
    }

    .note {
        margin-left: 214px;

        &:before {
            font-size: 0.7em;
            line-height: 1em;
            content: '▲ ';
            position: relative;
            top: -3px;
        }

        .noteline {
            display: flex;
            align-items: center;

            .pre {
                display: inline-block;
                margin: 3px 1em;
                padding: 2px;
                border: 0px;
            }
        }
    }

    .buttons {
        margin: 1em;

        > * {
            margin: 0 1em;
        }
    }
}

.sheetsList {

    .oneSheetRow {
        padding: 0.3em 0 0.3em 0.3em;
        display: flex;
        align-items: center;

        &.odd {
            background-color: rgba(0,0,0,0.1);
        }

        &:hover {
            background-color: lightgreen;
        }

        &:before {
            color: gray;
            font-size: 1.8em;
            content: ' • ';
        }

        &.enabled:before {
            color: #58d358;
            font-size: 2em;
            content: ' • ';
        }

        .name {
            flex: 1;
            font-size: 1.2em;
            font-weight: bold;
            margin: 0 0.5em;
        }

        .space {
            margin: 0 1em;
        }

        .lastrun, .status {
            font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
            font-size: 0.9em;
            font-weight: bold;
            margin: 0 1em;
        }

        .status {
            &.SCHEDULED, &.RESCHEDULED {color: darkorange;}
            &.RUNNING {color: green;}
            &.FAILED {color: #E8333C;}
            &.COMPLETE {color: darkgreen;}
            &.INACTIVE {color: darkgray;}
        }
    }
}