.printListEntry {
    margin: 0.5em 0 0.5em 6px;
    padding-left: 6px;
    border-left: 4px solid blue;
}

.oneSetting {
    display: block;
}

.linkStyle, .inlineLinkStyle {
    margin: 0.5em;
    cursor: pointer;
    color: darkblue;
    text-decoration: underline;
}

.linkStyle {
    display: block;
}

.inlineLinkStyle {
    margin: 0.5em 2em;
    font-size: 80%;
    font-weight: bold;
    user-select: none;
}