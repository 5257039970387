@import '../../../../styles/_base.scss';

:global {
    .advanced-select {
        flex: 1 1 auto;

        &__multi-value__label {
            .fa {
                margin-right: 5px;
            }
        }

        &__menu {
        }

        &__menu-list {
        }

        &__option {
            padding: 8px 12px;
            display: flex;
            cursor: pointer;
            .fa {
                padding: 0 0 0 5px;
            }
            &:hover {
                background: #eeeeee;
            }
            &:active {
                background: #eeeeee;
            }
            &-label {
                flex: 1 1 100%;
            }
            &-wrapper {
                display: flex;
            }

            // &--highlighted {
            //   color: $primaryColor;
            //   font-weight: 800;
            // }
        }
    }
}
