@import '../../../../../../../styles/base';
@import '../../../../../../components/IconButton.module';

.oneOf {
    @extend .button;
    background: $color-oneOf;
    &:hover {
        background: $color-oneOf-darker;
    }
}
.firstOf {
    @extend .button;
    margin-right: 5px;
    background: $color-firstOf;
    &:hover {
        background: $color-firstOf-darker;
    }
}
