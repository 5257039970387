.copyButton {
    margin: 10px;
    margin-right: 0;
    display: inline-block;
}

.changed {
    color: orange;
}
.toastHolder,
.changeHolder {
    height: 1em;
    color: #0e647d;
}

.active {
    border: 1px solid black;
    padding: 10px;
    padding-top: 0px;
    border-radius: 10px;
}
