.container {
    background: #fff;
    padding: 15px;
    box-shadow: 0 2px 0 rgba(120, 129, 146, 0.14);
    border-radius: 4px;
    margin-bottom: 10px;

    .heading {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    > div > div {
        &:last-of-type {
            flex: 1;
        }
    }
}

.box {
    padding: 10px;
    border: 1px solid #ddd;
}

.contentsToRight {
    display: flex;
    justify-content: flex-end;

    & > * {
        margin-left: 3px;
    }
}

.colorBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
}