$topBarHeight: 55px;
$headerHeight: 165px;

.header {
    background-color: #e8ebf2;
    height: ($topBarHeight + $headerHeight);
    //width: 100%;
    position: fixed;
    top: 0;
    padding-top: $topBarHeight;
    overflow-y: auto;
    margin-top: 0.5em;
    z-index: 999;
    margin-right: 0.5em;

    .headerVertical {
        display: flex;
        flex-direction: row;

        .loadButtonDiv {
            justify-content: center;
            margin-left: 0.5em;
            min-width: 6em;
        }
    }
}

.taskSection {
    padding-top: ($headerHeight);
}

.or {
    color: darkgray;
    font-size: 12px;
    font-weight: bold;
    margin: 0 2em;
}

.filterSection {
    margin: 0.25em 0;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
}

.filterContainer {
    .type {
        border: 1px solid lightyellow;
        background: lightyellow;
        border-radius: 8px;
        padding: 4px 6px;
        margin: 0.25em 0.75em;
        font-size: 10px;
        font-weight: bold;
        cursor: pointer;
        user-select: none;

        &.selected {
            border: 1px solid lightgreen;
            background: lightgreen;
        }

        &.unselected {
            border: 1px solid #ee9fa8;
            background: #ee9fa8;
        }
    }

    .smallLink {
        color: #3fa8ff;
        cursor: pointer;
        font-size: 12px;
        user-select: none;

        &:hover {
            color: blue;
        }
    }
}

.task {
    display: block;
    background: #f0f4fa;
    margin: 1em 0.5em;
    border: 1px solid darkgray;
    &Selected {
        background: rgba(75, 163, 243, 0.1);
    }
}

.splitHeaderRow {
    align-items: center;
    border-bottom: 1px solid darkgray;
    display: flex;
    margin-bottom: 0.5em;

    div {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: 0.75em 0;

        &.noPadding {
            padding: 0;
        }

        .bold {
            font-weight: bold;
        }

        .fieldName {
            color: darkgray;
            display: block;
            font-family: monospace;
            font-size: 12px;
            font-weight: bold;
        }

        &:not(:last-of-type) {
            border-right: 1px solid darkgray;
        }

        .status {
            font-weight: bold;

            &.statusScheduled {
                color: darkorange;
            }

            &.statusInactive {
                color: darkgray;
            }

            &.statusFailed {
                color: red;
            }

            &.statusComplete {
                color: darkgreen;
            }

            &.statusNotReady {
                color: darkblue;
            }

            &.statusUnknown {
                background: black;
                color: white;
            }
        }

        .executionTime {
            font-weight: bold;

            &.notRunYet {
                color: darkgray;
            }
        }

        .scheduledTime {
            font-size: 12px;
            font-weight: normal;
            color: black;
            display: block;
        }
    }
}

.taskInfo {
    padding: 0.5em;
}

.expandableParameters,
.nonExpandableParameters {
    font-weight: bold;
}

.expandableParameters {
    display: inline-block;

    &:before {
        background: #eee;
        color: #ff9900;
        content: 'Show contents';
    }

    &.expanded {
        width: auto;
        display: block;

        &:before {
            content: 'Hide contents';
        }
    }
}

.splitButtons {
    align-items: center;
    border-top: 1px solid darkgray;
    cursor: pointer;
    display: flex;
    margin-top: 1.5em;
    position: relative;
    user-select: none;

    a {
        text-decoration: none;
    }

    &:before {
        color: darkgray;
        content: 'ACTIONS';
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        text-align: center;
        top: -14px;
        width: 100%;
    }

    > * {
        color: #0050ff;
        display: flex;
        flex: 1;
        font-weight: bold;
        justify-content: center;
        padding: 0.75em 0;

        &:not(:last-child) {
            border-right: 1px solid darkgray;
        }

        &:hover {
            background-color: #ffff80;
        }

        &:active {
            background-color: #80ff80;
        }
    }

    .warnAction {
        color: red;

        &:not(.disabled):hover {
            background-color: #ffb66a;
        }

        &:not(.disabled):active {
            background-color: #ff6978;
        }
    }

    .disabled {
        background-color: #b4b4b4;
    }

    .textMargin {
        margin-left: 0.5em;
    }
}

.selectedDateOrTime {
    font-weight: bold;
}

.newSection {
    margin-top: 1em;
}

.sliderCols {
    margin-top: 0.5em;
    display: flex;
    flex-direction: row;
    position: relative;

    * {
        display: flex;
        flex: 1;
        justify-content: center;
    }

    &.timeLabels {
        font-size: 18px;
        font-weight: bold;

        *:not(:first-of-type):before {
            content: ':';
            position: absolute;
            left: 50%;
            font-size: 14px;
        }
    }
}

input[type='range'] {
    appearance: none;
    width: 100%;
    height: 9px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

input[type='range']::-webkit-slider-thumb {
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
}

.paginationContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1em;

    .paginationNumber {
        font-weight: bold;
        margin: 0 6px;
    }
}

.inline {
    display: inline-flex;
}

.selectAll {
    &Container {
        display: flex;
        align-items: center;
    }
    &Label {
        margin: 0 0 0 10px;
        font-weight: bold;
    }
    &Text {
        margin: 0 0 0 10px;
    }
}

.horizontalDivider {
    display: inline-block;
    color: darkgrey;
    margin: 0 2em;
}

.selectedTasksButton {
    margin: 0 0 0 10px;
}
