.pageWrapper {
    .metaHeader {
        margin: 1em;
        display: flex;

        h2 {
            flex: 1;
        }

        .headerButtons {
            display: flex;
            flex-direction: row;
            align-items: center;

            .headerButton {
                margin-left: 1em;
            }
        }
    }
}
