$infoBoxBgColor: #ffdfa3;
$infoBoxFgColor: #000;

$warnBoxBgColor: #ff7776;
$warnBoxFgColor: #fff;

.infoBox {
    display: block;
    padding: 6px;
    border-radius: 4px;
    user-select: none;
    margin: 0.5em 1em;
    font-size: 12px;
    border: 1px solid darken($infoBoxBgColor, 20);
    color: $infoBoxFgColor;
    background-color: $infoBoxBgColor;

    &:before {
        margin-right: 0.5em;
    }
}

.warnBox {
    @extend .infoBox;

    border: 1px solid darken($warnBoxBgColor, 10);
    color: $warnBoxFgColor;
    background-color: $warnBoxBgColor;
}

.tip {
    font-weight: bold;
    margin-right: 0.5em;
}

.inlineSelect {
    display: inline-block;
    margin: 0 1em;
}

.editorContainer {
    border: 1px solid #d8d8d8;
}

.saveBtn {
    margin: 1em;
}
