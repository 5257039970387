.bigWrapper {
  display: block;
  width: 100%;
}

.dbPicker {
  height: 2em;
  background: darkgrey;
  border-bottom: 1px solid gray;
  color: white;
  display: flex;
  align-items: center;
  padding: 2em;

  .text {
    text-decoration: underline;
    margin-right: 1em;
  }

  .selectedDb, .db {
    cursor: pointer;
    margin: 0.5em;
    padding: 1em;

    i {
      margin-right: 0.5em;
    }
  }

  .selectedDb {
    font-weight: bold;
  }
}

.wrapper {
  display: block;
  padding: 1em;
  width: 100%;

  .header {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
  }

  .info {
    font-style: italic;
    margin: 1em 0 2em 0;
  }

  .labelsWrapper {
    margin-top: 1em;
    display: flex;
    height: 1200px;
    width: 100%;

    .labelsHeader {
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 0.5em;
    }

    .labelsLeft {
      overflow: scroll;
      min-width: 200px;

      .searchBox {
        display: flex;
        align-items: center;

        .search {
          margin: 0.5em 0.5em 0.5em 0;
        }

        .searchFilterWithData {
          cursor: pointer;
          font-family: Consolas, Menlo, Monaco, monospace;
          font-weight: bold;
          color: gray;
          font-size: 1em;

          &.active {
            color: black;
          }
          &.inactive {
            color: darkred;
            text-decoration: line-through;
          }
        }
      }

      .labelWithData, .labelWithoutData, .labelIgnore {
        cursor: pointer;
        color: darkblue;
        user-select: none;
      }

      .labelWithData:after {
        content: ' D';
      }

      .labelIgnore:after {
        content: ' I';
      }

      .labelWithData:after, .labelIgnore:after {
        font-family: Consolas, Menlo, Monaco, monospace;
        font-weight: bold;
        color: black;
        font-size: 1em;
      }
    }

    .labelsDetails {
      flex: 1;
      margin-left: 1em;

      .ignored {
        color: darkgrey;
        font-style: italic;
      }

      .definitionBox {
        background-color: #e5e5e5;
        margin: 0.5em 0;
        padding: 0.5em 0;

        .definition {
          font-weight: bold;
        }

        iframe {
          width: 800px;
          height: 6em;
          border: 1px solid rgba(0,0,0,0.1);
        }
      }

      .labelDetailsDisabled {
        color: darkgrey;
        font-style: italic;
      }

      .labelDataRow > label {
        display: flex;
        align-items: center;

        > span {
          width: 225px;
        }

        > input {
          width: 120px;
        }
      }

      .labelDataRow, .labelDataRowImages {
        .labelValueDisabled {
          color: darkgrey;
          margin-left: 1em;
        }

        .images {
          display: flex;
          width: 100%;
          overflow-x: scroll;
          align-items: flex-start;

          .imageBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0.5em;

            .image {
              width: 200px;
              height: 150px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position-y: center;
              cursor: pointer;

            }
            .imageAllLabels {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              max-height: 200px;
              overflow-y: scroll;

              .imageOneLabel {
                &.focus {
                  font-weight: bold;
                }

                > span {
                  margin-right: 1em;
                }
              }
            }
            .imageLabel {
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
      }

      .affectedText {
        margin: 1em 0 -0.5em 0;
      }

      .labelDataRowImages {
        margin: 0.5em 0;
      }

      .noneAffected {
        color: darkgrey;
        font-style: italic;
        margin: 1.5em 0;
      }

      .loadAffected {
        margin-top: 1em;
      }
    }
  }
}

