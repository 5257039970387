.container {
    margin-top: 10px;
}

.box {
    margin-top: 20px;
    padding: 20px;
    background-color: #dedede;
}

.forcedBox {
    @extend .box;

    & * {
        color: #610000;
    }
}

.button1 {
    margin-left: 10px;
}

.reuseRow {
    padding: 5px;
    //background-color: #4ba3f3;
    border-bottom: 1px solid lightgrey;

    &:hover {
        background-color: #4ba3f3;
        color: white;
        cursor: pointer;
    }

    .inputs {
        color: grey;
        font-size: smaller;
    }
}

.first {
    background-color: #c8ffd1;
}

.reuseRowCreate {
    @extend .reuseRow;
    background-color: #6362df;
    color: white;
}

.inline {
    display: inline-flex;
}

.buttonMargin {
    margin: 1em 0;
}