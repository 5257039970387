.subArea {
    margin-left: 1em;
    border-left: 5px solid #737dff;
    padding-left: 1em;

    &:not(:first-of-type) {
        margin-top: 1em;
    }
}

.headerSmall {
    font-size: 15px;
    font-weight: bold;
    margin: 8px 0 6px 0;
}

.errorInput {
    background-color: orange;
}

.select {
    padding: 0px;
}
