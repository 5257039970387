.settingsContainer {
    margin: 1em;
}

.header {
    font-size: 12px;
    font-weight: bold;
    &>* {
        text-align: center;
    }
}

.entryRow, .header {
    width: 100%;
    padding: 0.3em;
    display: flex;
    align-content: center;
    align-items: center;
    &>* {
        flex: 50;
        &:first-child, &:nth-child(3), &:last-child {
            flex: 120px;
        }
        &:nth-child(5) {
            flex: 400px;
        }
    }

    .input {
        height: 37px;

        &.pretendDisabled {
            background-color: lightgrey;
            color: darkgrey;
        }
    }

    &.duplicate {
        background-color: rgba(255, 0, 0, 0.3);
    }

    .notUsed, .notUsed > * {
        background-color: #e6e6e6;
    }
}

.buttonMargin {
    margin-top: 1em;
}