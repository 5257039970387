@import '../../../../../../../styles/base';

.block {
    &:hover {
        background-color: #fafafa;
        color: #4ba3f3;
        box-shadow: 2px 2px 10px #4ba3f3;
    }
    //border-radius: 4px;
    padding: 20px;
    //border: 1px solid black;
    text-align: center;
    width: 200px;
    height: 200px;
    background-color: #fff;
    color: #000;
    float: left;
    box-shadow: 2px 2px 10px #7c8494;
    transition: all 0.15s ease;
    border-radius: 5px;
}

.huge {
    display: block;
    font-size: 100px;
    margin-bottom: 24px;
}
.text {
    font-size: 30px;
}
.arrow {
    font-size: 70px;
    margin: 20px;
}
