.pageWrapper {
    .categoryHeader {
        margin: 1em;

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }
    }
}

.noticeMe {
    font-weight: bold;
    font-size: 14px;
    color: darkred;
}

.streetviewGroup {
    border: 1px solid darkgray;
    margin: 1em;

    &.potentiallyDisqualified {
        background-color: lightyellow;
    }
    &.noLivingArea {
        background-color: lightgray;
    }

    .streetviewHeaderRow {
        display: flex;
        flex-direction: row;

        .identical {
            color: yellow;
            text-align: center;
            background-color: gray;
            margin-top: 3px;
            padding: 3px;
        }

        .streetviewId {
            color: darkgray;
        }

        > * {
            margin-right: 1em;
            font-weight: bold;
        }

        .streetviewMessage {
            flex: 1;
        }
    }

    .streetviewImageRow {
        padding-top: 0.5em;
        display: flex;

        .image {
            margin: 0.5em;
            flex: 1;
            display: block;
        }

        .imageInner {
            border: 1px solid black;
            max-height: 640px;
            min-width: 320px;
            min-height: 320px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .decisionRow {
        display: flex;

        .decisionButton {
            flex: 1;
            margin: 0.5em 1em;
        }
    }

    .decisionRowCentered {
        display: flex;
        flex-direction: column;
        align-items: center;

        .centerSmallerButton {
            margin: 0.5em 1em;
            background-color: #adae5a;
        }
    }
}

.altImage {
    box-sizing: border-box;
    width: 640px;
    height: 480px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.selectedImage {
        border: 3px solid black;
    }
}

.searchRow {
    margin-top: 0.5em;
    display: flex;
    align-content: center;
    align-items: center;

    .big {
        flex: 1;
    }

    span,
    select {
        margin-right: 1em;
    }

    input {
        width: 20em;
        margin-right: 1em;
    }
}

.header {
    font-size: 18px;
    font-weight: bold;
    padding: 4px;
    border-bottom: 1px solid darkgray;
    background-color: antiquewhite;
    display: flex;
    align-content: center;
    align-items: center;

    .title {
        flex: 0;
        margin-right: 6px;
    }

    .refresh {
        cursor: pointer;
        margin: 0 1em;
    }

    .middle {
        flex: 1;
    }

    .searchIcon {
        cursor: pointer;
        &.toggled {
            color: lightblue;
        }
    }

    .undo {
        margin: 0 1em 0 1em;
        .undoBtn {
            cursor: pointer;
        }
    }

    .searchBar {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        margin-right: 5em;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;

        > * {
            margin: 0 0.5em;
        }

        input[type="text"] {
            width: 200px;
        }

        &.disabled {
            color: darkgrey;
        }
    }
}

.streetviewMainGroup {
    position: relative;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 100%;

    &.yellow {
        background-color: lightyellow;
    }

    &.lightbrown {
        background-color: #ffbd65;
    }

    &.gray {
        background-color: #a6a6a6;
    }

    .topPanel, .message {
        max-width: 600px;
        width: 100%;

        >* {
            margin: 0 4px;
            font-weight: bold;
            &:before {
                font-weight: normal;
            }
        }
    }

    .mainPanel {
        width: 100%;
    }

    .noImage {
        max-height: 900px;
        min-height: 400px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .images {
        max-height: 900px;
        min-height: 600px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        &.oneImageOnly {
            min-height: 400px;
        }

        .selectedImage {
            border: 3px solid black;
        }

        .smallImages {
            display: flex;
        }

        .bigImage, .smallImage {
            position: relative;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            max-width: 200px;
        }

        .bigImage {
            position: relative;
            flex: 3;
            max-width: 600px;
            width: 100%;
            &:not(.selectedImage) {
                border: 3px solid transparent;
            }

            .note {
                position: absolute;
                left: 10%;
                top: 5px;
                padding: 4px 14px;
                color: white;
                background: darkgray;
                user-select: none;
            }

            .showBigVersion, .swapAlternativeImage {
                position: absolute;
                background-color: darkgrey;
                border-radius: 20px;
                padding: 7px;
                top: 5px;
                cursor: pointer;
            }

            .showBigVersion {
                left: 5px;
            }

            .swapAlternativeImage {
                right: 5px;
            }

            .arrowLeft, .arrowRight {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                width: 60px;
                height: 60%;
                top: 20%;
                cursor: pointer;
                color: white;
                font-weight: bold;
                font-size: 32px;
            }

            .arrowLeft {
                left: 5px;
                &::after {
                    content: '<';
                }
            }

            .arrowRight {
                right: 5px;
                &::after {
                    content: '>';
                }
            }

            .selectButton, .acceptImageButton, .declineImageButton, .moreOptionsButtonLeft, .moreOptionsButtonRight, .moreOptionsPopup {
                position: absolute;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 22%;
                bottom: 5px;
                color: white;
                font-weight: bold;
                height: 30px;
                user-select: none;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 14px;
                border: 3px solid white;
                &:not(.selectedButton) {
                    cursor: pointer;
                }
            }

            .selectButton {
                left: 39%;
                &.selectedButton {
                    color: gray;
                    &::after {
                        content: 'Selected';
                    }
                }
                &:not(.selectedButton) {
                    &::after {
                        content: 'Select';
                    }
                }
            }

            .previousAction {
                position: absolute;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                width: 100%;
                left: 0;
                bottom: 40px;
                color: white;
                font-weight: bold;
                height: 30px;
                user-select: none;

                > div {
                    padding: 0 1em;
                    background: rgba(0, 0, 0, 0.7);
                    height: 100%;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
            }

            .acceptImageButton {
                right: 16%;
                color: lightgreen;
                &::after {
                    content: 'Accept';
                }
            }

            .declineImageButton {
                left: 16%;
                color: lightcoral;
                &::after {
                    content: 'Decline';
                }
            }

            .moreOptionsButtonLeft {
                width: 30px;
                right: 87%;
            }

            .moreOptionsButtonRight {
                width: 30px;
                left: 87%;
                font-weight: normal;
            }
        }

        .smallImages {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;

            .smallImage {
                flex: 1;
                width: 200px;
                height: 200px;
                margin: 4px;
                &:not(.selectedImage) {
                    border: 3px solid transparent;
                }
            }

            .clickable {
                cursor: pointer;
            }
        }

        .idBox {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.7);
            top: 5px;
            left: 45%;
            color: white;
            font-weight: bold;
            user-select: none;
            &.small {
                width: 20%;
                min-width: 24px;
                height: 14px;
                font-size: 11px;
                border-radius: 8px;
            }
            &:not(.small) {
                width: 10%;
                height: 20px;
                font-size: 14px;
                border-radius: 12px;
            }
        }
    }

    .handled {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
    }
}

.spinContainer {
    display: flex;
    margin: 1em;
}

.oldVersion {
    margin-top: 3em;
    font-size: 20px;
    cursor: pointer;
    &:before {
        margin: 0 0.5em;
    }
}
