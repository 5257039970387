@import '../../../styles/base';

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    background: #7c8494;
    width: 100%;
    z-index: 3000;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $headerHeight;
        width: $sideBarWidth;
    }
}

.content {
    flex: 1;
    overflow: auto;
    display: flex;
}

.title {
    font-size: 20px;
}

.titleLink {
    color: #fff;
    margin-bottom: 5px;
}
//
//.mainContainer {
//    padding:$headerHeight  0;
//}
//
//
//.header {
//}
//

//
////.headerTab{
////  color: white;
////  height: 35px;
////  //line-height: 48px;
////
////  text-decoration: none;
////  display: block;
////  float: left;
////  padding: 10px 24px 0;
////}
//
//
////.header_tab:hover {
////  background-color: darken($primary-color,5);
////  text-decoration: none;
////
////}
////
////.header_tab.activeRoute {
////  @extend .header_tab:hover;
////  background-color: darken($primary-color,10);
////  border-bottom: 2px solid #324243;
////
////}
////
//

.headerContent {
    display: flex;
    flex: 1;
    height: $headerHeight;
    border-bottom: 1px solid #858e9e;
}

.headerContentLeft {
    display: flex;
    flex: 1;

    .userMenuBadge {
        border-right: 1px solid #858e9e;

        &:first-of-type {
            border-left: 1px solid #858e9e;
        }
    }
}

.headerContentRight {
    display: flex;
    flex: 0;

    .userMenuBadge {
        border-left: 1px solid #858e9e;
    }
}

.devIndicator {
    color: white;
    background-color: #ff3b00;
    border-radius: 10px;
    padding: 0 10px;
    font-weight: normal;
    margin-left: 10px;
    font-size: 14px;
}

.prodIndicator {
    color: #ff3b00;
    background-color: white;
    border-radius: 10px;
    padding: 0 10px;
    font-weight: normal;
    margin-left: 10px;
    font-size: 14px;
}

.userMenu {
    position: fixed;
    background: #fff;
    top: $headerHeight;
    right: 0;
    border: 1px solid #ccc;
    padding: 10px;
    color: black;
    width: 200px;
}

.userMenuBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    text-align: center;
    border-bottom: 1px solid #858e9e;
    font-size: 20px;
    cursor: pointer;
    position: relative;

    width: $headerHeight;
    height: $headerHeight;

    &.open {
        background-color: lighten(#7c8494, 3);
        color: #fff;

        i {
            border-right-color: #fff;
        }
    }
    &:hover {
        background-color: lighten(#7c8494, 10);
        color: #fff;

        i {
            border-right-color: #fff;
        }
    }
}

.userMenuItem {
    cursor: pointer;
    font-size: 14px;
    padding: 4px 10px;

    &:hover {
        background-color: $primary-color-lighter;
        color: white;
    }
}

.leftArrow {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;

    border-right: 4px solid #ccc;

    position: absolute;
    left: 11px;
}

.username {
    font-size: 16px;
    font-weight: bold;
    padding: 4px 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efebee;
}

MenuContainer {
    background-color: #ff0000;
}
