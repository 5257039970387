:global {
    .api-texts {
        &__scrollable_table {
            thead tr {
                th {
                    position: sticky !important;
                    top: 0;
                    z-index: 1;
                }
            }
            tbody tr.not_verified {
                background: darken(#f5dfdf, 1);
                &:hover {
                    background: darken(#f5dfdf, 8);
                }
                td {
                    border-top: 1px solid darken(#f5dfdf, 10);
                }
            }
        }
    }
}
