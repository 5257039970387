.oneRow {

}

.note {
  border: 1px dashed rgba(0, 0, 0, 0.3);
  background-color: lightyellow;
  padding: 0.5em;
  margin: 0.5em 0;
  max-width: 500px;
}

.loadSaveBtn {
  margin: 0.5em;
}

.group {
  display: flex;
}

.label {
  margin: 0.2em 0.5em;
  font-size: 12px;
}

.disabled {
  background-color: lightgrey;
  color: darkgrey;
}

.removeBtn {
  margin: 0.5em;
}

.shortcuts {
  display: flex;

  .shortcut {
    font-family: Consolas, Menlo, Monaco, monospace;
    font-size: 0.9em;
    font-weight: bold;
    margin: 0 1em 0 0;
    text-decoration: underline;
    color: darkblue;
    cursor: pointer;
  }
}