.pageWrapper {
    .categoryHeader {
        margin: 1em;

        h3 {
            font-style: italic;

            &[data-amount='0'] {
                color: darkgreen;
            }
        }
    }
}

.categoriesWrapper {
    margin: 0.5em;

    .content {
        background: #fff;
        padding-bottom: 10px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        .categoryDataContainer {
            margin: 0 1em;

            .error {
                margin: 1em;
                color: red;
                font-weight: bold;
            }
        }
    }

    .categoryRow {
        margin: 1em 0;
        padding: 1em 0.5em;
        line-height: 2em;
        background: #7c8494;
        color: white;
        display: flex;
        align-items: center;
        font-weight: bold;

        [type='checkbox'] {
            margin-right: 0.5em;
        }
    }

    .itemButtons {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex: 1;

        //a {
        //    background: #9098a9;
        //
        //    &:hover {
        //        background-color: lighten(#9098a9, 5);
        //    }
        //}
    }

    .plusButton {
        margin-top: 1em;
    }
}

.spellCheckHeader {
    margin: 1em 0 0 1em;
    font-weight: bold;
}

.spellCheckGroup {
    display: flex;
    margin: 0 1em 0 1em;
    border: 1px solid darkgray;
    padding: 0.5em;

    .verksamhetText {
        flex: 1;
        user-select: none;

        .onlyWord,
        .middleWord,
        .startWord,
        .endWord {
            border: 1px dashed red;
        }

        .middleWord {
            border-left: none;
            border-right: none;
        }
        .startWord {
            border-right: none;
        }
        .endWord {
            border-left: none;
        }

        .selectedWord {
            background-color: lightblue;
        }

        .changedWord {
            background-color: lightgreen;
        }

        .preChangedWord {
            color: darkgreen;
        }
    }
}

.previewNoGibberish {
    margin: 0 1em;
    border: 1px solid darkgray;
    border-top: none;
    padding: 0.5em;

    .previewBox {
        position: relative;
        padding-right: 4em;
        min-height: 50px;
    }

    .previewCopyButton {
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -17px;
        z-index: 999;
    }

    .copiedPreview {
        display: none;
        font-size: 24px;
        color: darkgreen;
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -12px;
        z-index: 1000;

        &[data-show-copied='true'] {
            display: block;
        }
    }
}

.spellCheckActionGroupWrapper {
    display: flex;

    .spellCheckActionGroup {
        flex: 1;
        margin: 0 0 0 1em;
        border: 1px solid darkgray;
        border-top: none;
        padding: 0.5em;

        .spellCheckActionGroupInner {
            display: flex;

            .nothing {
                margin-left: 1em;
                font-style: italic;
                font-weight: bold;
                color: darkgray;
            }
        }

        .actionRow {
            margin-top: 0.5em;
            display: flex;
            align-items: center;

            & > * {
                margin-right: 1em;
            }

            & > input {
                margin-left: 1em;
                margin-right: 0.5em;
            }

            .staticButtonWidth {
                width: 10em;
            }

            .actionValueResult {
                margin-left: 2em;
            }
        }
    }

    .spellCheckExtraOptionsGroup {
        margin: 0 1em 0 0;
        border: 1px solid darkgray;
        border-top: none;
        border-left: none;
        padding: 0.5em;
    }
}

.chooseLocaleParent {
    margin-bottom: 0.5em;

    .chooseLocale {
        display: inline-block;
        padding: 5px;
        height: 40px;
        cursor: pointer;

        :not(:first-child) {
            margin-left: 0.5em;
        }

        &.selected {
            border: 2px solid gray;
            background: rgba(0, 255, 0, 0.4);
        }
    }
}

hr {
    margin: 1em 0;
}

.shelfCorporationRow {
    display: flex;
    margin: 8px 0;
    padding: 4px;
    align-items: center;

    &:nth-of-type(odd) {
        background-color: #d7dbe2;
    }

    > * {
        margin: 0 1em;
    }

    .shelfCoroprationText {
        flex: 1;
    }
}

.gibberishInputs {
    margin-top: 1em;

    textarea {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        width: 100%;
        height: 150px;
    }
}
