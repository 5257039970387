.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    flex: 1;

    > div {
        flex: 1 1 auto;

        &:last-of-type {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.input {
    height: 30px;
    padding: 4px;
    display: inline-block;
}

.inputComment {
    height: 30px;
    width: 25em;
    padding: 4px;
    display: inline-block;
}

.error {
    color: red;
}

.modifiedAndSearch {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;

    :last-child {
        margin-left: 0.5em;
    }
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    a {
        margin-left: 2px;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    &:hover .tooltipWrapper {
        visibility: visible !important;
        bottom: 60px !important;
    }

    .tooltipWrapper {
        visibility: hidden;
        position: absolute;
        z-index: 1;
        bottom: auto;
        top: auto;
        left: 0;
        right: 0;
        height: 34px;

        .tooltipContent {
            width: calc(60vw - 300px);
            background-color: rgba(80, 80, 80, 1);
            color: #fff;
            text-align: center;
            padding: 0.4em;
            border-radius: 4px;
        }
    }
}

.hideMe {
    position: absolute;
    visibility: visible;
    width: auto;
    height: auto;
    animation: cssAnimation 5s forwards;
    background-color: #cc3d4c;
    z-index: 5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    &Green {
        background-color: #89b410;
    }
}

@keyframes cssAnimation {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
